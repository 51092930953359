import * as React from 'react';
import { useParams } from 'react-router-dom';

import { Chip, Grid, CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { FlexBox, FlexBoxColumn } from '../../shared/components';

import { useAppDispatch, useAppSelector } from '../../shared/hooks';

import { getCompanyEntityThunk, getCompanySioandSdgThunk } from './companiesSlice';
import { getAccountSettingsAccountThunk } from '../accounts/account-settings/accountSettingsSlice';
import SocialImpactGraph from './SocialImpactGraph';
import { AccountType } from '../../shared/enums';

export function CompanyIntelligence() {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { id } = params;
  const { company, companySioandSdg, loading } = useAppSelector((state) => state.company);
  const state = useAppSelector((x) => x.accountSettings);
  const { general } = state;
  const { account } = general;
  const { type } = { ...account };

  React.useEffect(() => {
    if (id && !company) {
      dispatch(getCompanyEntityThunk({ id }));
    }
    if (company?.name) {
      dispatch(getCompanySioandSdgThunk({ companyName: company.name }));
    }
  }, [id, company]);

  React.useEffect(() => {
    dispatch(getAccountSettingsAccountThunk());
  }, [dispatch]);

  const isBuywith = () => type === AccountType.procurement || type === AccountType.supplier;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '0 0 1rem 0',
        position: 'relative',
        gap: '1rem',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '20px',
        flexWrap: 'wrap',
        background: 'white',
      }}
    >
      {loading ? (
        <CircularProgress size={200} />
      ) : (
        <>
          <FlexBox sx={{ width: '100%' }} className="content__container header">
            <Typography className="Header">{`${
              isBuywith() ? 'BuyWith' : 'SellWith'
            } Intelligence`}</Typography>
            <Typography className="SubHeader">{company?.name}</Typography>
            <Typography>
              This page provides information about this company’s social responsibility goals and
              performance, and which partner and program from ScaleWith’s Social Impact Network is
              best suited to maximize business value for the company.
            </Typography>
          </FlexBox>
          <FlexBoxColumn className="content__container">
            <Typography className="Header">Top Issue Areas</Typography>
            <Typography>
              These topic areas are most materially relevant to this company based on ScaleWith’s
              algorithm.
            </Typography>
            <FlexBox sx={{ width: '100%', gap: '2rem', flexWrap: 'wrap' }}>
              {company?.topIssueAreas.map(
                (ele) => ele && <Chip label={ele.theme} key={ele.theme} />,
              )}
            </FlexBox>
          </FlexBoxColumn>
          <FlexBoxColumn className="content__container">
            <Typography className="Header">Social Impact Rating</Typography>
            <Typography>{`The data below shows how ${company?.name} ranks compared with other companies in the ${company?.industry} industry.`}</Typography>
            {Object.values(company?.metrics || {}).map((metric) =>
              metric.display ? (
                <Box
                  sx={{
                    width: '100%',
                    boxSizing: 'border-box',
                    border: '1px solid #D6D3D3',
                    borderRadius: '20px',
                  }}
                >
                  <FlexBox
                    sx={{
                      width: '100%',
                      alignContent: 'center',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      padding: '2rem 0',
                    }}
                  >
                    <Typography>{`${metric.label} ${metric.order}`}</Typography>
                    <SocialImpactGraph
                      companyName={company?.name || ''}
                      industryName={company?.industry || ''}
                      industry={metric.industryAverage}
                      company={metric.overall}
                      showLabels
                    />
                  </FlexBox>
                </Box>
              ) : null,
            )}
          </FlexBoxColumn>
          {companySioandSdg && companySioandSdg?.length > 0 && (
            <Grid
              container
              className="content__container grid"
              sx={{ borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
            >
              {companySioandSdg?.map((item) =>
                item?.sdgs.length || item?.siosAlignedWithScaleWith.length ? (
                  <Box
                    sx={{
                      border: '1px solid #C4C4C4',
                      padding: '2rem',
                      borderRadius: '10px',
                      width: '95%',
                    }}
                  >
                    <Typography className="Header" sx={{ paddingBottom: '15px' }}>
                      {item.label}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <Grid item xs={5} spacing={1} sx={{ borderRight: '1px solid #D9D9D9' }}>
                        <Typography className="Header">UN SDG Alignment</Typography>
                        {item?.sdgs.length ? (
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-around',
                              padding: '10px 0px',
                            }}
                          >
                            {item?.sdgs &&
                              item?.sdgs.map((ele) => (
                                <Box key={ele}>
                                  <Typography>{ele}</Typography>
                                </Box>
                              ))}
                          </Box>
                        ) : null}
                      </Grid>
                      <Grid item xs={5} sx={{ padding: '0px 25px' }}>
                        <Typography className="Header">
                          Social Impact Network Organization
                        </Typography>
                        {item?.siosAlignedWithScaleWith.length ? (
                          <ol>
                            {item?.siosAlignedWithScaleWith &&
                              item?.siosAlignedWithScaleWith.map((ele) => (
                                <li style={{ fontSize: '11pt' }} key={ele}>
                                  {ele}
                                </li>
                              ))}
                          </ol>
                        ) : null}
                      </Grid>
                    </Box>
                  </Box>
                ) : null,
              )}
            </Grid>
          )}
        </>
      )}
    </Box>
  );
}

import axios from 'axios';

const PathToIndustryMapJSON = 'appdata/industry-map.json'

// Function to fetch JSON data from a public S3 URL
export const  downloadIndustryMapJSON = async()=> {
  try {
      const response = await axios.get(`${window.location.origin}/${PathToIndustryMapJSON}`);
      console.debug("Fetch industry map JSON succeeded.");
      return response.data;
  } catch (error) {
      console.error("Fetch industry map JSON failed.", error);
      throw error;
  }
}
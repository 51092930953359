import React from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { SelectAmountFormData } from '../../../shared/interfaces';
import { FlexBox, FlexBoxColumn } from '../../../shared/components';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import {
  getCompanyEntityThunk,
  getCompanySioandSdgThunk,
} from '../../company-intelligence/companiesSlice';
import { getAccountSettingsAccountThunk } from '../../accounts/account-settings/accountSettingsSlice';
import SocialImpactGraph from '../../company-intelligence/SocialImpactGraph';
// import { SDGIcon } from '../../ProposalPage/SDGIcon';
import { AccountType } from '../../../shared/enums';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';

const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  flexDirection: 'column',
  overflow: 'scroll',
};

const modalContainer = {
  width: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: '95%',
  right: '0',
  left: '0',
  alignItems: 'center',
  bottom: '0px',
};

interface CompanyProps {
  formData: SelectAmountFormData;
}

export default function IntellgienceModal({ formData }: CompanyProps) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { company, companySioandSdg } = useAppSelector((state) => state.company);
  const state = useAppSelector((x) => x.accountSettings);
  const { general } = state;
  const { account } = general;
  const { type } = { ...account };
  const [companyId, setCompanyId] = React.useState<string>('');
  const [companyName, setCompanyName] = React.useState<string>('');

  React.useEffect(() => {
    setCompanyId(formData.customerId!);
    setCompanyName(formData.customerName!);
    if (companyId) {
      dispatch(getCompanyEntityThunk({ id: companyId }));
    }
    if (companyName) {
      dispatch(getCompanySioandSdgThunk({ companyName }));
    }
  }, [companyId, formData.customerId, formData.customerName, companyName, dispatch, open]);

  React.useEffect(() => {
    dispatch(getAccountSettingsAccountThunk());
  }, [dispatch]);

  const isBuywith = () => type === AccountType.procurement || type === AccountType.supplier;

  return (
    <>
      <Button
        sx={{
          color: colors.primary,
          fontSize: fonts.fontSize14,
          fontWeight: fonts.fontWeight700,
          textDecoration: 'underline',
        }}
        onClick={handleOpen}
      >
        Explore how we recommend programs?
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={style}
      >
        <Box sx={modalContainer}>
          <FlexBox sx={{ width: '90%' }} className="content__container header">
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography className="Header">{`${
                isBuywith() ? 'BuyWith' : 'SellWith'
              } Intelligence`}</Typography>
              <IconButton
                aria-label="delete"
                onClick={handleClose}
                sx={{ color: 'white', position: 'absolute', right: '10%' }}
                size="large"
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Box>
            <Typography className="SubHeader">{company?.name}</Typography>
            <Typography>
              This page provides information about this company’s social responsibility goals and
              performance, and which partner and program from ScaleWith’s Social Impact Network is
              best suited to maximize business value for the company.
            </Typography>
          </FlexBox>
          <FlexBoxColumn className="content__container">
            <Box
              sx={{
                border: '1px solid #C4C4C4',
                padding: '30px',
                borderRadius: '10px',
                width: '95%',
              }}
            >
              <Typography className="Header" sx={{ padding: '10px 0px' }}>
                Top Cause Areas
              </Typography>
              <Typography sx={{ padding: '10px 0px' }}>
                These top cause areas are most materially relevant to this company, based on
                ScaleWith’s algorithm..
              </Typography>
              <FlexBox sx={{ width: '100%', gap: '1rem', flexWrap: 'wrap' }}>
                {company?.topIssueAreas.map(
                  (ele) =>
                    ele && (
                      <Typography
                        sx={{
                          borderRight: `2px solid ${colors.strokeDefault}`,
                          height: '30px',
                          paddingRight: '14px',
                          display: 'flex',
                          alignItems: 'center',
                          fontWeight: `${fonts.fontWeight600} !important`,
                          fontSize: `${fonts.fontSize14} !important`,
                          color: `${colors.textPrimary} !important`,
                        }}
                      >
                        {ele.theme}
                      </Typography>
                    ),
                )}
              </FlexBox>
              <Typography
                sx={{ padding: '10px 0px', marginTop: '80px' }}
              >{`The data below shows how ${company?.name} rates compared with other companies in the ${company?.industry} industry.`}</Typography>
              {Object.values(company?.metrics || {}).map((metric) =>
                metric.display ? (
                  <SocialImpactGraph
                    companyName={company?.name || ''}
                    industryName={company?.industry || ''}
                    industry={metric.industryAverage}
                    company={metric.overall}
                    showLabels
                  />
                ) : null,
              )}
            </Box>
          </FlexBoxColumn>
          {/* <FlexBoxColumn className="content__container">
            <Box
              sx={{
                border: '1px solid #C4C4C4',
                padding: '2rem',
                borderRadius: '10px',
                width: '95%',
              }}
            >
              <Typography className="Header" sx={{ padding: '10px 0px' }}>
                Social Impact Rating
              </Typography>
              <Typography
                sx={{ padding: '10px 0px' }}
              >{`The data below shows how ${company?.name} rates compared with other companies in the ${company?.industry} industry.`}</Typography>
              {Object.values(company?.metrics || {}).map((metric) =>
                metric.display ? (
                  <Box
                    sx={{
                      width: '100%',
                      boxSizing: 'border-box',
                      border: '1px solid #D6D3D3',
                      borderRadius: '20px',
                    }}
                  >
                    <FlexBox
                      sx={{
                        width: '100%',
                        alignContent: 'center',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        padding: '1rem 0',
                      }}
                    >
                      <Typography>{`${metric.label} ${metric.order}`}</Typography>
                      <SocialImpactGraph
                        companyName={company?.name || ''}
                        industryName={company?.industry || ''}
                        industry={metric.industryAverage}
                        company={metric.overall}
                      />
                    </FlexBox>
                  </Box>
                ) : null,
              )} 
            </Box>
          </FlexBoxColumn> */}
          {companySioandSdg && companySioandSdg?.length > 0 && (
            <Grid
              container
              className="content__container grid"
              sx={{ borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}
            >
              {companySioandSdg?.map((item) =>
                item?.sdgs.length || item?.siosAlignedWithScaleWith.length ? (
                  <Box
                    sx={{
                      border: '1px solid #C4C4C4',
                      padding: '2rem',
                      borderRadius: '10px',
                      width: '95%',
                    }}
                  >
                    <Typography className="Header" sx={{ paddingBottom: '15px' }}>
                      {item.label}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <Grid item xs={5} spacing={1} sx={{ borderRight: '1px solid #D9D9D9' }}>
                        <Typography className="Header">UN SDG Alignment</Typography>
                        {item?.sdgs.length ? (
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-around',
                              padding: '10px 0px',
                            }}
                          >
                            {item?.sdgs &&
                              item?.sdgs.map((ele) => (
                                <Box key={ele}>
                                  {/* <SDGIcon code={ele.code} /> */}
                                  <Typography>{ele}</Typography>
                                </Box>
                              ))}
                          </Box>
                        ) : null}
                      </Grid>
                      <Grid item xs={5} sx={{ padding: '0px 25px' }}>
                        <Typography className="Header">
                          Social Impact Network Organization
                        </Typography>
                        {item?.siosAlignedWithScaleWith.length ? (
                          <ol>
                            {item?.siosAlignedWithScaleWith &&
                              item?.siosAlignedWithScaleWith.map((ele) => (
                                <li style={{ fontSize: '11pt' }} key={ele}>
                                  {ele}
                                </li>
                              ))}
                          </ol>
                        ) : null}
                      </Grid>
                    </Box>
                  </Box>
                ) : null,
              )}
            </Grid>
          )}
        </Box>
      </Modal>
    </>
  );
}

import { AccountStatus } from '../../shared/enums';
import {
  GridRequest,
  GridResponse,
  InviteSupplierRequest,
  ProcurementMetricsRequest,
  Supplier,
} from '../../shared/interfaces';
import { httpSecuredCommon, cancelToken } from '../httpCommon';

const base = '/suppliers';
const companyBase = '/company/registration';

export const SUPPLIERS_API_ROUTES = {
  getSuppliers: base,
  postResendInvite: (id: string) => `${base}/${id}/resend-invite`,
  postInviteSuppliers: `${base}/invite`,
  getSupplier: (id: string, accountId: string) => `${base}/${id}?accountId=${accountId}`,
  deleteInviteSupplier: (id: string, accountId: string) => `${base}/${id}?accountId=${accountId}`,
  patchSupplier: (id: string) => `${base}/${id}`,
  putSuppliersInvite: `${base}/invite`,
  getMetricsCauseArea: `${base}/metrics/cause-areas`,
  getMetricsDetail: `${base}/metrics/detail`,
  postMetricsCSV: `${base}/metrics/download`,
  getCustomFields: (id: string) => `${base}/${id}/app-settings`,
  getCompanies: (queryString: string) => `${companyBase}/${queryString}`,
};

export const getSupplier = async (id: string, accountId: string) => {
  const response = await httpSecuredCommon.get<Supplier>(
    SUPPLIERS_API_ROUTES.getSupplier(id, accountId),
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const getSuppliers = async (request: GridRequest) => {
  const response = await httpSecuredCommon.get<GridResponse<Supplier>>(
    SUPPLIERS_API_ROUTES.getSuppliers,
    {
      cancelToken: cancelToken.token,
      params: request,
    },
  );
  return response;
};

export const getMetricSuppliers = async (request: GridRequest,id: string, accountId: string) => {
  const response = await httpSecuredCommon.get<GridResponse<Supplier>>(
    SUPPLIERS_API_ROUTES.getSupplier(id, accountId),
    {
      cancelToken: cancelToken.token,
      params: request,
    },
  );
  return response;
};

export const postInviteSupplier = async (payload: {
  suppliers?: Array<InviteSupplierRequest>;
  accountId?: string;
}) => {
  const response = await httpSecuredCommon.post(SUPPLIERS_API_ROUTES.postInviteSuppliers, payload, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const postResendInvite = async (payload: {
  id: string;
  accountId: string;
  username: string;
}) => {
  const { id, ...newPayload } = payload;
  const response = await httpSecuredCommon.post(
    SUPPLIERS_API_ROUTES.postResendInvite(id),
    newPayload,
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const deleteSupplierInvite = async (payload: { id: string; accountId: string }) => {
  const response = httpSecuredCommon.delete(
    SUPPLIERS_API_ROUTES.deleteInviteSupplier(payload.id, payload.accountId),
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const patchSupplier = async (
  payload: { status: AccountStatus; accountId: string },
  id: string,
) => {
  const response = httpSecuredCommon.patch(SUPPLIERS_API_ROUTES.patchSupplier(id), payload, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const putSuppliersInvite = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  console.dir(file);
  
  const response = httpSecuredCommon.put(SUPPLIERS_API_ROUTES.putSuppliersInvite, formData, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const getSupplierMetricsCauseArea = async (request: ProcurementMetricsRequest) => {
  const response = httpSecuredCommon.post(SUPPLIERS_API_ROUTES.getMetricsCauseArea, request, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const getSupplierMetricsDetail = async (request: ProcurementMetricsRequest) => {
  const response = httpSecuredCommon.post(SUPPLIERS_API_ROUTES.getMetricsDetail, request, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const postSupplierMetricsCSV = async (request: ProcurementMetricsRequest) => {
  const response = httpSecuredCommon.post(SUPPLIERS_API_ROUTES.postMetricsCSV, request, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const getSupplierCustomFields = async (values: { id: string; accountId: string }) => {
  const { accountId, id } = values;
  const response = await httpSecuredCommon.get(SUPPLIERS_API_ROUTES.getCustomFields(id), {
    cancelToken: cancelToken.token,
    params: { accountId },
  });
  return response;
};

export const getCompanies = async (searchValue: string) => {
  const queryString = `?name=${searchValue}&count=10&offset=0&orderBy=name&descending=false`;
  const response = await httpSecuredCommon.get<any>(SUPPLIERS_API_ROUTES.getCompanies(queryString), {
    cancelToken: cancelToken.token,
  });
  return response;
};
import { GridRequest, GridResponse } from '../../shared/interfaces';
import {
  SioUSerRequest,
  SIO,
  // PostSIORequest,
  PostSioUSerRequest,
  PatchSIORequest,
  SioUserResponse,
  AddSioUserRequest,
  UpdateSioUserRequest,
  PostSioFormValues,
} from '../../shared/interfaces/sio/sio';
import { httpSecuredCommon, httpSecuredCoreCommon, cancelToken } from '../httpCommon';

const base = '/sios';
const coreBase = '/api/v2/core';

export const SIO_API_ROUTES = {
  getSio: (id: string) => `${base}/${id}`,
  getSioByPassword: (id: string) => `${base}/siouser/${id}`,
  getSios: base,
  postSio: `${coreBase}/workflows`,
  patchSio: (id: string) => `${base}/${id}`,
  updateSioByUser: (id: string) => `${base}/siouser/${id}/`,
  postSioUser: (id: string) => `${base}/siouser/${id}`,
  deleteSio: (id: string) => `${base}/${id}`,
  sendEmail: (id: string) => `${base}/sendform/${id}`,
  sendMpaEmail: (id: string) => `${base}/sendform/mpa/${id}`,
  sioAction: (id: string, action: string) => `${base}/${id}/${action}`,
  getSioStatus: `${base}/status`,
  addSioUser: '/users/sio-users',
  getUserList: '/users/sio-users',
  editSioUser: (id: string) => `/users/sio-users/${id}`,
  // editMpa:(id:string) => `${base}/mpa/${id}`,
  getUserById: (id: string) => `/users/sio-users/${id}`,
  uploadFile: (id: string) => `${base}/siousers/file/${id}`,
  uploadAdminFile: (id: string) => `${base}/file/${id}`,
  getSiosByStatus: (statusFilter: string) => `${base}/${statusFilter}`,
  getMpa: (id: string) => `${base}/mpa/${id}`,
  downloadFile: (id: string, password: string) => `${base}/siousers/file/${id}/${password}`,
  addBlockList: (id: string) => `${base}/blocklist/${id}`,
  getIndustries: `${base}/industries`,
};

export const downloadFile = async (request: any) => {
  const { id, url, password, name } = request;
  const response = await httpSecuredCommon.get(SIO_API_ROUTES.downloadFile(id, password), {
    cancelToken: cancelToken.token,
    params: {
      url,
    },
    responseType: 'blob',
  });

  const responseURL = window.URL.createObjectURL(new Blob([response.data]));
  const a = document.createElement('a');
  a.href = responseURL;
  a.download = name;
  window.URL.revokeObjectURL(responseURL);
  return response;
};

export const getSio = async (id: string) => {
  const response = await httpSecuredCommon.get<SIO>(SIO_API_ROUTES.getSio(id), {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const getSioByPassword = async (id: string) => {
  const response = await httpSecuredCommon.get<SIO>(SIO_API_ROUTES.getSioByPassword(id), {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const getSios = async (request: GridRequest) => {
  const response = await httpSecuredCommon.get<GridResponse<SIO>>(SIO_API_ROUTES.getSios, {
    cancelToken: cancelToken.token,
    params: request,
  });
  return response;
};

export const postSio = async (sio: PostSioFormValues) => {
  const response = await httpSecuredCoreCommon.post<SIO>(SIO_API_ROUTES.postSio, sio, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const addNewSioUser = async (sio: AddSioUserRequest) => {
  const response = await httpSecuredCommon.post<SioUserResponse>(SIO_API_ROUTES.addSioUser, sio, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const postSioUser = async (sio: PostSioUSerRequest, id: string) => {
  const response = await httpSecuredCommon.post<SIO>(SIO_API_ROUTES.postSioUser(id), sio, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const postBlockList = async (blockList: any, id: string) => {
  const response = await httpSecuredCommon.post<any>(SIO_API_ROUTES.addBlockList(id), blockList, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const getIndustriesList = async () => {
  const response = await httpSecuredCommon.get(SIO_API_ROUTES.getIndustries, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const updateSioUser = async (sio: PostSioUSerRequest, id: string) => {
  const response = await httpSecuredCommon.patch<SIO>(SIO_API_ROUTES.updateSioByUser(id), sio, {
    cancelToken: cancelToken.token,
  });
  return response;
};
export const patchSio = async (id: string, sio: PatchSIORequest) => {
  const response = await httpSecuredCommon.patch<SIO>(SIO_API_ROUTES.patchSio(id), sio);
  return response;
};
export const updateSioPost = async (id: string, sio: PatchSIORequest) => {
  const response = await httpSecuredCommon.post<SIO>(SIO_API_ROUTES.patchSio(id), sio);
  return response;
};

export const deleteSio = async (id: string) => {
  const response = await httpSecuredCommon.delete(SIO_API_ROUTES.patchSio(id));
  return response;
};

export const sendEmail = async (id: string, data: object) => {
  const response = await httpSecuredCommon.post(SIO_API_ROUTES.sendEmail(id), data);
  return response;
};
export const sendMpaEmail = async (id: string, data: object) => {
  const response = await httpSecuredCommon.post(SIO_API_ROUTES.sendMpaEmail(id), data);
  return response;
};
export const sioAction = async (id: string, action: string) => {
  const response = await httpSecuredCommon.post(SIO_API_ROUTES.sioAction(id, action));
  return response;
};
export const getSioStatus = async () => {
  const response = await httpSecuredCommon.get(SIO_API_ROUTES.getSioStatus, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const getSioUserList = async (request: SioUSerRequest) => {
  const response = await httpSecuredCommon.get<GridResponse<SioUserResponse>>(
    SIO_API_ROUTES.getUserList,
    {
      cancelToken: cancelToken.token,
      params: request,
    },
  );
  return response;
};

export const updateUser = async (id: string, sio: UpdateSioUserRequest) => {
  const response = await httpSecuredCommon.put<SioUserResponse>(
    SIO_API_ROUTES.editSioUser(id),
    sio,
  );
  return response;
};

export const updateMpa = async (id: string, mpa: any) => {
  const response = await httpSecuredCommon.patch(SIO_API_ROUTES.getMpa(id), mpa);
  return response;
};

export const submitMpa = async (id: string, mpa: any) => {
  const response = await httpSecuredCommon.post(SIO_API_ROUTES.getMpa(id), mpa);
  return response;
};

export const getUserById = async (id: string) => {
  const response = await httpSecuredCommon.get<SioUserResponse>(SIO_API_ROUTES.getUserById(id), {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const getSiosByStatus = async (request: GridRequest, statusFilter: string) => {
  const response = await httpSecuredCommon.get<GridResponse<SIO>>(
    SIO_API_ROUTES.getSiosByStatus(statusFilter),
    {
      cancelToken: cancelToken.token,
      params: request,
    },
  );
  return response;
};

export const uploadFile = async (id: string, data: any) => {
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  };
  const response = await httpSecuredCommon.post(SIO_API_ROUTES.uploadFile(id), data, config);
  return response;
};

export const uploadAdminFile = async (id: string, data: any) => {
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  };
  const response = await httpSecuredCommon.post(SIO_API_ROUTES.uploadAdminFile(id), data, config);
  return response;
};

export const getMpaDetails = async (id: string) => {
  const response = await httpSecuredCommon.get(SIO_API_ROUTES.getMpa(id), {
    cancelToken: cancelToken.token,
  });
  return response;
};

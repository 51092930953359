import axios from 'axios';
import { setupInterceptorsTo } from './interceptor';

export const cancelToken = axios.CancelToken.source();

export const getTenantIdCookie = () => {
  let name;
  if (window.location.origin.includes('dev')) {
    name = 'dev_tenantId=';
  } else if (window.location.origin.includes('qa')) {
    name = 'qa_tenantId=';
  } else {
    name = 'tenantId=';
  }
  let userClaims: any;
  if (window.location.origin.includes('dev')) {
    userClaims = 'dev_user_claims_details=';
  } else if (window.location.origin.includes('qa')) {
    userClaims = 'qa_user_claims_details=';
  } else {
    userClaims = 'user_claims_details=';
  }

  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; ) {
    const cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      const tenantId = cookie.substring(name.length);

      // Access the dev_user_claims_details cookie
      const claimsDetailsCookie = cookieArray.find((c) => c.trim().startsWith(userClaims));
      if (claimsDetailsCookie) {
        try {
          const claimsDetailsString = claimsDetailsCookie.substring(userClaims.length + 1);
          const claimsDetails = JSON.parse(claimsDetailsString);

          // Check if the tenantId is in the tenantAccessGrants array
          const matchingGrant = claimsDetails.tenantAccessGrants.find(
            (grant: any) => String(grant.id) === String(tenantId),
          );

          if (matchingGrant) {
            return matchingGrant.id;
          }

          // If not found, return the id where default = true
          const defaultGrant = claimsDetails.tenantAccessGrants.find(
            (grant: any) => grant.default === true,
          );

          if (defaultGrant) {
            return defaultGrant.id;
          }
        } catch (error) {
          console.log('Error parsing dev_user_claims_details cookie:', error);
        }
      }
    }
    i += 1;
  }

  return null;
};

export const addCancelTokenEvent = (signal: AbortSignal) => {
  signal.addEventListener('abort', () => {
    cancelToken.cancel();
  });
};

export const httpCommon = setupInterceptorsTo(
  axios.create({
    baseURL: window.PORTAL_API_ENDPOINT,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
  }),
);

export const httpMatchmakingCommon = setupInterceptorsTo(
  axios.create({
    baseURL: window.PORTAL_API_ENDPOINT,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
  }),
);

export const httpMatchmakingUiCommon = setupInterceptorsTo(
  axios.create({
    baseURL: window.PORTAL_UI_ENDPOINT,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
  }),
);

export const httpSecuredCommon = setupInterceptorsTo(
  axios.create({
    baseURL: window.PORTAL_API_ENDPOINT,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
    }
    }),

  true,
);


export const httpSecuredCommonWithCoreHttpAPI = setupInterceptorsTo(
  axios.create({
    baseURL: window.CORE_HTTP_API_URL,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
    }
    }),

  true,
);

httpSecuredCommon.interceptors.request.use((config) => {
  const tenantId = getTenantIdCookie();

  if (
    tenantId &&
    config.method?.toUpperCase() === 'GET' &&
    !config.url?.endsWith('/users/profile')
  ) {
    config.headers['sw-tenant-id'] = tenantId;
  }

  return config;
});

export const httpSecuredCoreCommon = setupInterceptorsTo(
  axios.create({
    baseURL: window.CORE_HTTP_API_URL,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
  }),
  true,
);

httpSecuredCoreCommon.interceptors.request.use((config) => {
  const tenantId = getTenantIdCookie();

  if (
    tenantId &&
    config.method?.toUpperCase() === 'GET' &&
    !config.url?.endsWith('/users/profile')
  ) {
    config.headers['sw-tenant-id'] = tenantId;
  }

  

  return config;
});

export const httpSharedApiProxy = setupInterceptorsTo(
  axios.create({
    baseURL: window.SHARED_API_PROXY,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
  }),
  true,
);

export const httpAuth = setupInterceptorsTo(
  axios.create({
    baseURL: window.AUTH_API_ENDPOINT,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
    },
  }),
);

export const httpIncetiveProposalVerify = (password: string) =>
  setupInterceptorsTo(
    axios.create({
      baseURL: window.PORTAL_API_ENDPOINT,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        incentiveAuthKey: password,
      },
    }),
  );

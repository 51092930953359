import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { FlexBox } from '../../shared/components';
import colors from '../../assets/scss/color.module.scss';
import fonts from '../../assets/scss/font.module.scss';

interface Props {
  industry: number;
  company: number;
  companyName: string;
  industryName: string;
  showLabels: boolean;
}

const SocialImpactGraph: FC<Props> = ({
  industry,
  company,
  companyName,
  industryName,
  showLabels,
}) => {
  const color = ['#CD4829', '#D86F00', '#F8A044', '#FFC88B', '#6CC1AC', '#369980', '#2A615C'];

  const rankScale = [
    { label: 'Very Poor' },
    { label: 'Poor' },
    { label: 'Fair' },
    { label: 'Moderate' },
    { label: 'Good' },
    { label: 'Very Good' },
    { label: 'Excellent' },
  ];

  const getArrowPosition = (value: number) => {
    const percent = value * 10;
    return `${percent}%`;
  };

  return (
    <Box
      sx={{
        width: '100%',
        boxSizing: 'border-box',
        border: '1px solid #D6D3D3',
        borderRadius: '20px',
      }}
    >
      {showLabels && (
        <Typography
          sx={{
            paddingLeft: '20px',
            paddingY: '10px',
            color: `${colors.textPrimary} !important`,
            fontSize: `${fonts.fontSize16} !important`,
            fontWeight: `${fonts.fontWeight600} !important`,
          }}
        >
          {`${companyName} rates `}
          <i>{`${rankScale[Math.ceil((7 * company) / 10) - 1].label}`}</i> &nbsp;in social
          responsibility.
        </Typography>
      )}
      <FlexBox
        sx={{
          width: '100%',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          padding: '1rem 0',
        }}
      >
        {/* <Typography>{`${metric.label} ${metric.order}`}</Typography> */}
        <Box
          className="rating-bar"
          sx={{
            minHeight: '200px',
            position: 'relative',
            display: 'flex',
            width: '90%',
            flexWrap: 'wrap',
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <FlexBox
            className="left-arrow"
            style={{
              flexWrap: 'wrap',
              justifyContent: 'center',
              textAlign: 'center',
              alignContent: 'center',
              left: getArrowPosition(industry),
              top: '-15px',
              // transform: `translate(${getArrowPosition(industry - 1)},0)`,
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '150px',
            }}
          >
            <Typography
              sx={{
                fontSize: `${fonts.fontSize18} !important`,
                fontWeight: `${fonts.fontWeight700} !important`,
                color: colors.textPrimary,
              }}
            >
              {showLabels ? industryName : 'Industry Average'}
            </Typography>
            <Typography
              sx={{
                fontSize: `${fonts.fontSize16} !important`,
                fontWeight: `${fonts.fontWeight400} !important`,
                color: colors.textPrimary,
              }}
            >
              {rankScale[Math.ceil((7 * industry) / 10) - 1].label}
            </Typography>
            <ArrowDropDown sx={{ margin: '0 auto' }} />
          </FlexBox>
          <Box
            className="rating-bar-inner"
            sx={{
              display: 'flex',
              width: '100%',
              height: '17px',
              borderRadius: '20px',
              overflow: 'hidden',
            }}
          >
            {color.map((ele, i) => (
              <Box
                key={ele}
                sx={{
                  minWidth: '14.28%',
                  backgroundColor: ele,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  flexGrow: i,
                }}
              />
            ))}
          </Box>
          <FlexBox
            className="right-arrow"
            style={{
              bottom: '0',
              left: getArrowPosition(company),
              // transform: `translate(${getArrowPosition(company)},0)`,
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              maxWidth: '150px',
            }}
          >
            <ArrowDropUp sx={{ margin: '0 auto' }} />
            <Typography
              sx={{
                fontSize: `${fonts.fontSize18} !important`,
                fontWeight: `${fonts.fontWeight700} !important`,
                color: colors.textPrimary,
              }}
            >
              {companyName}
            </Typography>
            <Typography
              sx={{
                fontSize: `${fonts.fontSize16} !important`,
                fontWeight: `${fonts.fontWeight400} !important`,
                color: colors.textPrimary,
              }}
            >
              {rankScale[Math.ceil((7 * company) / 10) - 1].label}
            </Typography>
          </FlexBox>
        </Box>
      </FlexBox>
    </Box>
  );
};

export default SocialImpactGraph;

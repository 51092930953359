import React, { FC, useState } from 'react';
import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { Check, Report, Task } from '@mui/icons-material';
import { FlexBox } from '../../../../shared/components';
import { IndustryGrouped, InviteSupplierFormConfig } from '../../../../shared/interfaces';
import {
  clearInviteSuppliersForm,
  setSupplierInviteData,
  setSupplierInviteFileName,
  setSupplierInviteList,
  uploadCSVThunk,
} from './supplierInviteSlice';
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks';
import { isNullOrWhitespace, Visible } from '../../../../shared/utils';
import { getCompanies } from '../../../../api';
import fonts from '../../../../assets/scss/font.module.scss';
import colors from '../../../../assets/scss/color.module.scss';

interface Props {
  isEditMode: boolean;
  editIndex: number | null;
  cleanEditMode: () => void;
}

const FileNameComponent = (props) => {
  const { sx, fileName, visible } = props;
  if (visible) {
    return (
      <FlexBox sx={sx}>
        <Task color="success" />
        {fileName}
      </FlexBox>
    );
  } 
  return null;
};

const FileErrorComponent = (props) => {
  const { sx, fileError, visible } = props;
  if (visible) {
    return (
      <FlexBox sx={sx}>
        <Report color="error" />
        {fileError?.message}
      </FlexBox>
    );
  }
  return null;
};

export const InviteSupplierForm: FC<Props> = (props) => {
  const { editIndex, isEditMode, cleanEditMode } = { ...props };
  const dispatch = useAppDispatch();
  const state = useAppSelector((x) => x.inviteSupplier);
  const { listSuppliers, formValues, industriesGrouped, loadingIndustries, fileName, csvFileError } = {
    ...state,
  };
  const { lastName, company, firstName, industry, username } = { ...formValues };

  const formConfig: InviteSupplierFormConfig = {
    firstName: { value: firstName ?? '', label: 'First Name', required: true },
    lastName: { value: lastName ?? '', label: 'Last Name', required: true },
    company: { value: company ?? '', label: 'Search Company', required: true },
    industry: { value: industry ? industry.label : '', label: 'Industry', required: true },
    email: { value: username ?? '', label: 'Email', required: true },
  };

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const validateIsExpandedForm = () =>
    !isNullOrWhitespace(formConfig.firstName?.value) ||
    !isNullOrWhitespace(formConfig.lastName?.value) ||
    !isNullOrWhitespace(formConfig.company?.value) ||
    !isNullOrWhitespace(formConfig.industry?.value) ||
    !isNullOrWhitespace(formConfig.email?.value) ||
    isExpanded;
  
  const fileUploadedOk = () => !isNullOrWhitespace(fileName) && !csvFileError;
  const fileUploadedWithError = () => !isNullOrWhitespace(fileName) && csvFileError;

  const getTextButton = () => (isEditMode && editIndex !== null ? 'Confirm' : 'Add');

  const addSupplierToList = () => {
    const isAlreadyEmail = listSuppliers.find(
      (supplier) => supplier.value.user.username === username,
    );
    if (isAlreadyEmail && isEditMode === false) {
      toast.warning(`'${username}' is duplicate`);
      return;
    }
    const payload = {
      value: {
        account: {
          industry: {
            industry: industry?.group ?? '',
            subIndustry: industry?.value ?? '',
          },
          company: {
            name: company,
          },
        },
        user: {
          username,
          firstName,
          lastName,
        },
      },
    };

    if (isEditMode && editIndex !== null) {
      const supplierList = [...listSuppliers];
      const isErrorMessage = supplierList[editIndex].errorMessagesServer ?? undefined;

      supplierList[editIndex] = { value: payload.value, errorMessagesServer: isErrorMessage };
      dispatch(setSupplierInviteList([...supplierList]));
      cleanEditMode();
      return;
    }

    dispatch(setSupplierInviteList([...listSuppliers, { ...payload }]));
    dispatch(clearInviteSuppliersForm());
  };

  const isInvalidForm = () =>
    (formConfig.firstName?.required && isNullOrWhitespace(formConfig.firstName?.value)) ||
    (formConfig.lastName?.required && isNullOrWhitespace(formConfig.lastName?.value)) ||
    (formConfig.company?.required && isNullOrWhitespace(formConfig.company?.value)) ||
    (formConfig.industry?.required && isNullOrWhitespace(formConfig.industry?.value)) ||
    (formConfig.email?.required && isNullOrWhitespace(formConfig.email?.value));

  const getIndustryGrouped = (value: string) => {
    const findIndustry = industriesGrouped.find((item) => item.label === value);
    return findIndustry ?? null;
  };

  const onHandleUploadCSV = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0] ?? undefined;
    if (!file || !file?.name) {
      return;
    }
    dispatch(uploadCSVThunk(file));
    dispatch(setSupplierInviteFileName(file.name));
  };

  const [temporaryCompanies, settemporaryCompanies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [display, setDisplay] = useState(false);
  const [industryValues, setIndustryValues] = useState({
    subIndustry: '',
    industry: '',
  });

  const getCompaniesList = (value: string) => {
    getCompanies(value).then((companiesList) => {
      settemporaryCompanies(companiesList?.data?.results);
      const companiesArray = companiesList?.data?.results.map(
        (companyData: any) => companyData.name,
      );
      setCompanies(companiesArray);
    });
  };

  const commonTextFieldStyle = {
    '& label': {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight700,
    },
    '& input': {
      fontSize: fonts.fontSize18,
    },
    '&::placeholder': {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight700,
    },
  };

  return (
    <Box>
      <Typography>Enter the information of the user(s) you would like to invite</Typography>
      <FlexBox sx={{ flexDirection: 'column', gap: 3 }}>
        <FlexBox sx={{ gap: 3, flex: 1 }}>
          <TextField
            value={formConfig.firstName.value}
            sx={{ ...commonTextFieldStyle, flex: 1 }}
            label={formConfig.firstName.label}
            required={formConfig.firstName.required}
            onFocus={() => setIsExpanded(true)}
            onBlur={() => setIsExpanded(false)}
            onChange={(e) => dispatch(setSupplierInviteData({ firstName: e.target.value }))}
          />
          <TextField
            value={formConfig.lastName.value}
            sx={{ ...commonTextFieldStyle, flex: 1 }}
            label={formConfig.lastName.label}
            required={formConfig.lastName.required}
            onFocus={() => setIsExpanded(true)}
            onBlur={() => setIsExpanded(false)}
            onChange={(e) => dispatch(setSupplierInviteData({ lastName: e.target.value }))}
          />
        </FlexBox>
        <Visible condition={validateIsExpandedForm()}>
          <FlexBox sx={{ flexDirection: 'column', gap: 3 }}>
            <FlexBox sx={{ gap: 3, flex: 1 }}>
              {/* <TextField
                value={formConfig.company.value}
                sx={{ flex: 1 }}
                label={formConfig.company.label}
                required={formConfig.company.required}
                onFocus={() => setIsExpanded(true)}
                onBlur={() => setIsExpanded(false)}
                onChange={(e) => dispatch(setSupplierInviteData({ company: e.target.value }))}
              /> */}
              <Autocomplete
                sx={{ flex: 1 }}
                freeSolo
                disableClearable
                options={companies}
                value={formConfig.company.value}
                onInputChange={(event, newInputValue) => {
                  getCompaniesList(newInputValue);
                  dispatch(setSupplierInviteData({ company: newInputValue }));
                  setDisplay(false);
                  setIndustryValues({
                    subIndustry: '',
                    industry: '',
                  });
                }}
                onChange={(e, value: any) => {
                  const selectedCompany: any = temporaryCompanies.filter(
                    (companyName: any) => companyName.name === value,
                  );
                  if (selectedCompany) {
                    setDisplay(true);
                  } else {
                    setDisplay(false);
                  }
                  setIndustryValues({
                    industry: selectedCompany[0]?.sector,
                    subIndustry: selectedCompany[0]?.industry,
                  });
                  dispatch(
                    setSupplierInviteData({
                      industry: {
                        group: selectedCompany[0]?.sector,
                        label: selectedCompany[0]?.industry,
                        value: selectedCompany[0]?.industry,
                      },
                    }),
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    sx={commonTextFieldStyle}
                    required={formConfig.company.required}
                    {...params}
                    label={formConfig.company.label}
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                    }}
                  />
                )}
              />
              {!display ? (
                <Autocomplete
                  sx={{ flex: 1 }}
                  getOptionLabel={(option) => option.label}
                  options={industriesGrouped}
                  value={
                    industryValues?.subIndustry !== '' || formConfig?.industry?.value !== ''
                      ? getIndustryGrouped(formConfig.industry.value)!
                      : getIndustryGrouped('')
                  }
                  groupBy={(option) => option.group!}
                  loading={loadingIndustries}
                  onChange={(event: any, newValue: IndustryGrouped | null) => {
                    setIndustryValues({
                      industry: newValue?.group!,
                      subIndustry: newValue?.value!,
                    })
                    dispatch(
                      setSupplierInviteData({
                        industry: newValue,
                      }),
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={commonTextFieldStyle}
                      required={formConfig.industry.required}
                      {...params}
                      onFocus={() => setIsExpanded(true)}
                      onBlur={() => setIsExpanded(false)}
                      label={formConfig.industry.label ?? 'Industry'}
                    />
                  )}
                  disableClearable={formConfig.industry.required}
                />
              ) : (
                <TextField
                  value={industryValues?.subIndustry}
                  sx={{ ...commonTextFieldStyle, flex: 1 }}
                  label={formConfig.industry.label ?? 'Industry'}
                  disabled
                  onFocus={() => setIsExpanded(true)}
                  onBlur={() => setIsExpanded(false)}
                  // onChange={() =>
                  //   dispatch(
                  //     setSupplierInviteData({
                  //       industry: {
                  //         group: industryValues?.industry,
                  //         label: industryValues?.subIndustry,
                  //         value: industryValues?.subIndustry,
                  //       },
                  //     }),
                  //   )
                  // }
                />
              )}
            </FlexBox>
            <TextField
              value={formConfig.email.value}
              sx={{ ...commonTextFieldStyle, flex: 1 }}
              label={formConfig.email.label}
              required={formConfig.email.required}
              onFocus={() => setIsExpanded(true)}
              onBlur={() => setIsExpanded(false)}
              onChange={(e) => dispatch(setSupplierInviteData({ username: e.target.value }))}
            />
          </FlexBox>
        </Visible>
        <FlexBox sx={{ alignItems: 'center', gap: 2, flexDirection: 'column' }}>
          <FlexBox sx={{ gap: 2 }}>
            <Visible condition={isEditMode}>
              <Button
                onClick={cleanEditMode}
                variant="contained"
                sx={{ color: 'white', backgroundColor: '#EF4339' }}
              >
                Cancel
              </Button>
            </Visible>
            <Button
              disabled={isInvalidForm()}
              onClick={addSupplierToList}
              variant="contained"
              color="success"
              startIcon={<Check />}
              sx={{ backgroundColor: colors.primary, color: colors.secndary, fontWeight: fonts.fontWeight700 }}
            >
              {getTextButton()}
            </Button>
          </FlexBox>
          <Typography>Or import from a csv file</Typography>
          <Button variant="outlined" component="label" color="info">
            Choose File
            <input hidden accept=".csv" type="file" onChange={onHandleUploadCSV} />
          </Button>
           
          <FileNameComponent sx={{ gap: 1, alignItems: 'center' }} fileName={fileName} visible={fileUploadedOk()} />
          <FileErrorComponent sx={{ gap: 1, alignItems: 'center' }} fileError={csvFileError} visible={fileUploadedWithError()} />
            
        </FlexBox>
      </FlexBox>
    </Box>
  );
};

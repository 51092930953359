import React, { ReactNode } from 'react';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';

interface InfoCardProps {
  children: ReactNode;
  description: string;
}

const InfoCard: React.FC<InfoCardProps> = ({ description, children }) => (
  <Card
    variant="outlined"
    sx={{ margin: '20px 0', boxShadow: 'none', border: '1px solid #e0e0e0' }}
  >
    <CardHeader
      title={
        <Typography variant="h6" color="textSecondary">
          {description}
        </Typography>
      }
      sx={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #e0e0e0' }}
    />
    <CardContent
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '40px',
        alignItems: 'center',
      }}
    >
      {children}
    </CardContent>
  </Card>
);

export default InfoCard;

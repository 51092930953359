import { createAsyncThunk, createSlice, current, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import _ from 'lodash';
import Papa from 'papaparse';
import {
  addCancelTokenEvent,
  getIndustries,
  postInviteSupplier,
  // putSuppliersInvite,
} from '../../../../api';
import { RootState } from '../../../../app';
import { SupplierInviteScreenStatus } from '../../../../shared/enums';
import {
  IndustryGrouped,
  InviteSupplierRequest,
  InviteSupplierResponse,
} from '../../../../shared/interfaces';

interface FormValues {
  company: string;
  firstName: string;
  industry: IndustryGrouped | null;
  lastName: string;
  username: string;
}

interface SupplierListValues {
  value: InviteSupplierRequest;
  errorMessagesServer?: Array<string>;
  errorMessagesClient?: Array<string>;
}

export interface SupplierUserProfile {
  firstName: string,
  lastName: string,
  username: string,
  company: string,
  industry: string,
}

export type SupplierUserList = Array<SupplierUserProfile>;

export interface InviteSupplierState {
  formValues: FormValues;
  listSuppliers: Array<SupplierListValues>;
  loadingIndustries: boolean;
  industriesGrouped: Array<IndustryGrouped>;
  responseList: InviteSupplierResponse;
  success: boolean;
  loading: boolean;
  serverError: boolean;
  invitesSent: number;
  screenStatus: SupplierInviteScreenStatus;
  csvFileError: SerializedError,
  csvSupplierUserList: SupplierUserList;
  fileName?: string;
  headers: Array<string>
  inviteErrorMessage?: string
}

const initialState: InviteSupplierState = {
  listSuppliers: [],
  formValues: {
    company: '',
    firstName: '',
    industry: null,
    lastName: '',
    username: '',
  },
  loadingIndustries: false,
  industriesGrouped: [],
  success: false,
  responseList: {},
  loading: false,
  serverError: false,
  invitesSent: 0,
  screenStatus: SupplierInviteScreenStatus.Form,
  csvFileError: null,
  csvSupplierUserList: [],
  fileName: '',
  headers: [
    'firstName',
    'lastName',
    'company',
    'industry',
    'username'
  ],
  inviteErrorMessage: ""
};
const FirstName = 0;
const LastName = 1;
const Company = 2;
const Industry = 3;
const EmailUsername = 4;

const verifyCSVHeaderNames = (headerNames: Array<string>) => {

  const result = headerNames.reduce((arr, name) => {
    if (name === initialState.headers[FirstName]) {
      arr[FirstName] = 1;
    }
    if (name === initialState.headers[LastName]) {
      arr[LastName] = 1;
    }
    if (name === initialState.headers[Company]) {
      arr[Company] = 1;
    }
    if (name === initialState.headers[Industry]) {
      arr[Industry] = 1;
    }
    if (name === initialState.headers[EmailUsername]) {
      arr[EmailUsername] = 1;
    }
    return arr;
  }, new Array<number>(initialState.headers.length, ));
  return result.filter((c => c === 1)).length === initialState.headers.length;
};

export const getSupplierInviteIndustriesGroupedThunk = createAsyncThunk(
  'supplier/getIndustriesThunk',
  async (_void, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getIndustries();
    const groupedIndustries = data.flatMap((x) =>
      x.items.map((i) => ({ group: x.name, label: i.subIndustry, value: i.subIndustry })),
    );
    return groupedIndustries;
  },
);

export const uploadCSVThunk = createAsyncThunk<SupplierUserList, File>(
  'inviteSupplierCSV/UploadCSV',
  async (file: File, { signal }) => {
    addCancelTokenEvent(signal);
     const parsedCSV = new Promise<Papa.ParseResult<SupplierUserProfile> >( (resolve, reject) => {
      Papa.parse<SupplierUserProfile, File>(file, {
        complete: (parseResult) => {
          if (verifyCSVHeaderNames(parseResult.meta.fields)) {
            resolve(parseResult)
          } else {
            reject(new Error("Missing required supplier csv columns: Email, First Name, Last Name, Company, Industry"));
          }
        },
        error: (parseError) => reject(parseError),
        header: true,
        transformHeader: (header) => {
          const lcHeader = header.toLowerCase();
          if (lcHeader.indexOf('first') > -1) {
            return initialState.headers[FirstName];
          }
          if (lcHeader.indexOf('last') > -1) {
            return initialState.headers[LastName];
          }
          if (lcHeader.indexOf('industry') > -1) {
            return initialState.headers[Industry];
          }
          if (lcHeader.indexOf('email') > -1) {
            return initialState.headers[EmailUsername];
          }
          if (lcHeader.indexOf('company') > -1 && lcHeader.indexOf('industry') < 0) {
            return initialState.headers[Company];
          }
          return header;
        }
      })
    });
    const parseResult = await parsedCSV;
    return parseResult.data;
  },
);

export const postSupplierInviteThunk = createAsyncThunk<
  InviteSupplierResponse,
  void,
  { state: RootState }
>('supplier/postSupplierInvite', async (_void, { getState, signal, rejectWithValue }) => {
  addCancelTokenEvent(signal);
  const state = getState();
  const { inviteSupplier, app } = state;
  const { account } = { ...app };
  const { listSuppliers: invites } = { ...inviteSupplier };
  const supplierList = invites.map((item) => ({ ...item.value }));
  const payload = { suppliers: [...supplierList], accountId: account?.id };

  try {
    const { data } = await postInviteSupplier(payload);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

const inviteSupplierSlice = createSlice({
  name: 'invite supplier',
  initialState,
  reducers: {
    clearInviteSuppliersState: (state) => {
      _.assign(state, initialState);
    },
    setSupplierInviteData: (state, action: PayloadAction<Partial<FormValues>>) => {
      _.assign(state.formValues, action.payload);
    },
    setSupplierInviteList: (state, action: PayloadAction<Array<SupplierListValues>>) => {
      state.listSuppliers = action.payload;
    },
    setSupplierInviteError: (state, action: PayloadAction<boolean>) => {
      state.serverError = action.payload;
    },
    clearInviteSuppliersForm: (state) => {
      _.assign(state.formValues, initialState.formValues);
      // Clear file upload values
      state.fileName = '';
      state.csvFileError = null;
    },
    setSupplierInviteScreenStatus: (state, action: PayloadAction<SupplierInviteScreenStatus>) => {
      state.screenStatus = action.payload;
    },
    setSupplierInviteFileName: (state, action: PayloadAction<string>) => {
      state.csvFileError = null;
      state.fileName = action.payload;
    },
    setHeaders: (state, action: PayloadAction<Array<string>>) => {
      state.headers = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getSupplierInviteIndustriesGroupedThunk.fulfilled,
        (state, action: PayloadAction<Array<IndustryGrouped>>) => {
          state.loadingIndustries = false;
          state.industriesGrouped = action.payload;
        },
      )
      .addCase(getSupplierInviteIndustriesGroupedThunk.rejected, (state) => {
        state.loadingIndustries = false;
        state.industriesGrouped = [];
      })
      .addCase(getSupplierInviteIndustriesGroupedThunk.pending, (state) => {
        if (!state.loadingIndustries) state.loadingIndustries = true;
      })
      .addCase(postSupplierInviteThunk.fulfilled, (state, action: PayloadAction<any>) => {
        const schemaErrors = action.payload.schema_errors;
        if (schemaErrors) {
          const indexErrorUser = Object.keys(schemaErrors.suppliers).map((item) =>
            parseInt(item, 10),
          );

          const defaultValue: Array<string> = [];

          const errorMessages = Object.values(schemaErrors.suppliers).map((n: any) => {
            if (!n.user) return [];
            const list = Object.values(n.user).reduce((prev, actual) => {
              if (!Array.isArray(actual) || !Array.isArray(prev)) return prev;
              return [...prev, ...actual];
            }, defaultValue);
            if (Array.isArray(list)) return [...list];

            return [];
          });

          const listSupp = [...current(state.listSuppliers)];

          const newList = listSupp.map((item, index) => {
            const isError = indexErrorUser.map((number, indexError) => {
              if (number === index) {
                return errorMessages[indexError];
              }
              return null;
            });
            const error = isError.find((messages) => !!messages);
            if (error) {
              return {
                ...item,
                errorMessagesServer: [...error],
              };
            }
            return { ...item };
          });
          state.serverError = true;
          state.invitesSent = 0;
          state.listSuppliers = newList;
          return;
        }
        if (action.payload.suppliers[0].message === "Success") {
          state.success = true;
         }
        else {
          state.success = false;
          state.inviteErrorMessage = action.payload.suppliers[0].message;
        }

        state.loading = false;
      })
      .addCase(postSupplierInviteThunk.rejected, (state, action: PayloadAction<any>) => {
        const schemaErrors = action.payload.schema_errors;
        if (schemaErrors) {
          const indexErrorUser = Object.keys(schemaErrors.suppliers).map((item) =>
            parseInt(item, 10),
          );
          const errorMessages = _.flatMapDeep(
            Object.values(schemaErrors.suppliers),
            (n: any) => n.user.username[0] ?? null,
          );

          const listSupp = [...current(state.listSuppliers)];

          const newList = listSupp.map((item, index) => {
            const isError = indexErrorUser.map((number, indexError) => {
              if (number === index) {
                return errorMessages[indexError];
              }
              return null;
            });
            const error = isError.find((messages) => messages !== null);
            if (error) {
              return {
                ...item,
                errorMessagesServer: [error],
              };
            }
            return { ...item };
          });
          state.serverError = true;
          state.invitesSent = 0;
          state.listSuppliers = newList;
        }
        state.loading = false;
      })
      .addCase(postSupplierInviteThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
        if (state.serverError) state.serverError = false;
        if (state.invitesSent !== 0) state.invitesSent = 0;
      })
      .addCase(uploadCSVThunk.fulfilled, (state, action: PayloadAction<SupplierUserList>) => {
        state.screenStatus = SupplierInviteScreenStatus.UploadedCSV;
        state.csvSupplierUserList = action.payload;
      })
      .addCase(uploadCSVThunk.rejected, (state, action) => {
        state.screenStatus = SupplierInviteScreenStatus.Form;
        state.csvFileError = action.error;
        state.csvSupplierUserList = [];
      })
      .addCase(uploadCSVThunk.pending, (state) => {
        if (state.screenStatus === SupplierInviteScreenStatus.Form)
          state.screenStatus = SupplierInviteScreenStatus.Loading;
      });
  },
});

export const {
  clearInviteSuppliersState,
  setSupplierInviteData,
  setSupplierInviteList,
  clearInviteSuppliersForm,
  setSupplierInviteError,
  setSupplierInviteScreenStatus,
  setSupplierInviteFileName,
  setHeaders
} = inviteSupplierSlice.actions;

export const inviteSuppliersReducer = inviteSupplierSlice.reducer;

import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { CompanySearch } from '../../shared/components/CompanySearch';
// import SocialImpactGraph from '../company-intelligence/SocialImpactGraph';
import colors from '../../assets/scss/color.module.scss';
import InfoCard from './supplier-intelligence/InfoCard';
// import ScoreBar from './supplier-intelligence/ScoreBar';
import LoadingSpinner from './supplier-intelligence/LoadingSpinner';
import { CompanyEntityCore } from '../../shared/interfaces/company/companyEntityCore';
import { getCompanyEntityCore } from '../../api';

const SupplierIntelligence = () => {
  const location = useLocation();
  const [company, setCompany] = useState<CompanyEntityCore>();
  const [selectedCompany, setSelectedCompany] = useState<{ name: string; id: string } | null>(null);
  const [isLoading, setLoading] = useState(false);

  const sioDescriptionString = `In network Social Impact Organizations supported by ${selectedCompany?.name}`;

  const fetchCompanyData = useCallback(async (companyId: string) => {
    setLoading(true);

    try {
      const response = await getCompanyEntityCore(companyId);
      const { docs } = response.data;
      const companyEntity = docs[0];
      setCompany(companyEntity);
      setSelectedCompany({ name: companyEntity.name, id: companyId });
    } catch (error) {
      console.error('Failed to fetch company data');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const companyId = searchParams.get('company_id');
    const companyName = searchParams.get('company_name');
    if (companyId && !selectedCompany) {
      fetchCompanyData(companyId);
    }

    if (companyName && !selectedCompany) {
      setSelectedCompany({ name: companyName, id: '' });
    }
  }, [location.search, fetchCompanyData, selectedCompany]);

  const handleCompanyChange = (event: any, newValue: { name: string; id: string } | null) => {
    if (newValue) {
      setSelectedCompany(newValue);
      fetchCompanyData(newValue.id);
    } else {
      setSelectedCompany(null);
    }
  };

  return (
    <Box sx={{ backgroundColor: 'white', padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Supplier Intelligence
      </Typography>
      <CompanySearch
        onChange={handleCompanyChange}
        customerName={selectedCompany?.name || ''}
        app="buywith"
      />

      {isLoading && <LoadingSpinner />}

      {!isLoading && company && (
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h6" gutterBottom>
            Cause Areas
          </Typography>
          <InfoCard description="These top cause areas are most materially relevant to this company, based on ScaleWith’s algorithm.">
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {company.themes.map(
                (ele) =>
                  ele && (
                    <Typography
                      key={ele.theme}
                      sx={{
                        borderRight: `2px solid ${colors.strokeDefault}`,
                        paddingRight: 2,
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 'fontWeightMedium',
                        fontSize: 'fontSize14',
                        color: 'text.primary',
                      }}
                    >
                      {ele.theme}
                    </Typography>
                  ),
              )}
            </Box>
          </InfoCard>

          {/* <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
            Company Rating
          </Typography>
          <InfoCard description="Corporate Social Responsibility (CSR) ratings">
            {Object.entries(company.metrics || {}).map(([key, metric]) =>
              metric.display && key === 'csrhub' ? (
                <SocialImpactGraph
                  key={key}
                  companyName={company.name || ''}
                  industryName={company.industry || ''}
                  industry={metric.industryAverage}
                  company={metric.overall}
                  showLabels
                />
              ) : null,
            )}
          </InfoCard> */}

          {/* <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
            CSR Sub Category Score
          </Typography>
          <InfoCard description="Community, Employees, Environment, Governance">
            {company &&
              company.csrhub &&
              company.csrhub.subcategories &&
              Object.entries(company.csrhub.subcategories).map(([category, score]) => (
                <ScoreBar key={category} category={category} score={score} />
              ))}
          </InfoCard> */}

          <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
            UN SDGs Alignment
          </Typography>
          <InfoCard description="Company supported United Nations Sustainable Development Goals (UNSDGs)">
            {company.unSDGs.map((sdg) => (
              <Typography key={sdg.code} sx={{ fontWeight: '600' }}>
                {' '}
                {sdg.code}: {sdg.name}{' '}
              </Typography>
            ))}
          </InfoCard>

          <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>
            Social Impact Network Organizations
          </Typography>
          <InfoCard description={sioDescriptionString}>
            {company.siosInNetwork.map((sio) => (
              <Typography key={sio.name} sx={{ fontWeight: '600' }}>
                {' '}
                {sio.name}
              </Typography>
            ))}
          </InfoCard>
        </Box>
      )}

      {!isLoading && !company && (
        <Box
          sx={{
            mt: 4,
            p: 3,
            border: '1px solid #ccc',
            borderRadius: '8px',
            backgroundColor: '#f9f9f9',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '70vh',
          }}
        >
          <InfoIcon sx={{ mr: 2, color: '#1976d2' }} />
          <Typography variant="h6" sx={{ fontWeight: 'medium' }}>
            Please select a Supplier to view intelligence
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default SupplierIntelligence;

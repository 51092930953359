// @ts-nocheck
import * as React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
// import { toast } from 'react-toastify';
import { AllCompanies, CompanyEntity } from '../interfaces';
// import { LightTooltip } from './StyledComponents';
import { getAllCompanyEntities } from '../../api/portal/companyEntityRequests';
import fonts from '../../assets/scss/font.module.scss';
import { downloadIndustryMapJSON } from '../../api/portal/instanceSectorsRequest';

// const filter = createFilterOptions<CompanyEntity>();
interface Props {
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<{}>, newValue: CompanyEntity) => void;
  customerName?: string;
  validateSpecialChar?: boolean;
  app: string;
}

// Industy Map Types
interface IndustryInfo {
  label: string;
  scoresId: string | null;
}

interface IndustryDataMap {
  framework: string;
  frameworkIndustries: Record<string, IndustryInfo>;
  industries: Record<string,string>;
}

function toTitleCase(name) {
  return name
    .trim()
    .split(/\s+/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

const MinimumSearchTermLength = 3;

/**
 * Get standard industry info for the provided industry name
 * @param industryName industry name
 * @param industryData industry data map
 * @returns 
 */
const getIndustryInfoByName = (industryName: string, industryData: IndustryDataMap) => industryData?.frameworkIndustries[industryData?.industries[industryName]];

/**
 * Get standard industry info for the provided framework code
 * @param industryCode industry framework code
 * @param industryData industry data map
 * @returns 
 */
const getIndustryInfoByCode = (industryCode: string, industryData: IndustryDataMap) => industryData?.frameworkIndustries[industryCode];


export const CompanySearch: React.FC<Props> = ({ onChange, customerName, app }) => {
  const [value, setValue] = React.useState<CompanyEntity | null>(null);
  const [inputValue, setInputValue] = React.useState<string>(customerName || '');
  const [companyNames, setCompanyNames] = React.useState<AllCompanies[]>([]);
  const placeholderText =
    app === 'buywith' ? 'Select or enter supplier name' : 'Select or enter buyer name';

  const fetchCompanies = async (searchText: string, fromParams: boolean = false) => {
    let response = {};
    // TODO  to use browser storage to reduce use of network as sector mapping will be seldom updated.
    const industrySectors = await downloadIndustryMapJSON();
    if (searchText.trim().length >= MinimumSearchTermLength) {
      response = await getAllCompanyEntities({
        name: searchText,
        count: 8,
        offset: 0,
        orderBy: 'name',
        descending: false,
      });

      if (response) {
        const transformedData = {
          ...response.data,
          docs: response.data.docs.map((doc) => {
            let id: any;
            let industryInfo: IndustryInfo;
            if (doc.ref) {
              id = doc.ref.dataId;
              industryInfo = getIndustryInfoByCode(doc.ref.industryCode, industrySectors);
            } else if (doc.industry) {
              industryInfo = getIndustryInfoByName(doc.industry, industrySectors);
              id = industryInfo?.scoresId;
            } 
            
            // Fallback to default
            if (!industryInfo) {
              industryInfo = getIndustryInfoByName('_default', industrySectors);
              if (!id) {
                id = industryInfo.scoresId ? industryInfo.scoresId : '000000000000000000000000';
              } else {
                // TODO: Publish metrics event
                console.warn(`Industry expected for ref on ${doc._id}`);
              }
            }

            return {
              ...doc,
              name: toTitleCase(doc.name),
              industryLabel: industryInfo.label,
              id,
            };
          }),
        };

        setCompanyNames(transformedData.docs);

        if (fromParams && response.data.docs.length > 0) {
          // const autoSelectedCompany = response.data.results[0];
          // setValue(autoSelectedCompany);
          // onChange({} as React.ChangeEvent<{}>, autoSelectedCompany);
        }
      }
    }
  };

  React.useEffect(() => {
    setInputValue(customerName || '');
    setValue(customerName ? ({ name: customerName, id: '' } as CompanyEntity) : null);
  }, [customerName]);

  React.useEffect(() => {
    if (customerName) {
      setInputValue(customerName);
      fetchCompanies(customerName, true);
    }
  }, [customerName]);

  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    newInputValue: string,
    reason: string,
  ) => {
    setInputValue(newInputValue);
    if (reason === 'input') fetchCompanies(newInputValue);
  };

  const handleValueChange = (event: React.ChangeEvent<{}>, newValue: CompanyEntity | null) => {
    if (newValue) {
      setValue(newValue);
      onChange(event, { name: newValue.name, id: newValue.id } as CompanyEntity);
    } else {
      setValue(null);
    }
  };

  return (
    <Autocomplete
      fullWidth
      sx={{
        fontSize: fonts.fontSize16,
        '& .MuiFormControl-root, & .MuiOutlinedInput-root': {
          minWidth: '100%',
          fontSize: fonts.fontSize16,
          padding: '0px',
          marginTop: '2px',
          height: 43,
        },
        lineHeight: '150%',
      }}
      className="select-enter-buyer-name-autocomplete"
      value={value}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleValueChange}
      options={companyNames}
      getOptionLabel={(option: CompanyEntity) => (option ? option.name : '')}
      renderOption={(props, option) => (
        <li {...props}>
          <div display="flex" width="100%">
            <span style={{ color: '#000' }}>{option.name}</span>
            <span style={{ fontStyle: 'italic', color: 'gray', marginLeft: 4 }}>
              {option.industryLabel}
            </span>
          </div>
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" placeholder={placeholderText} />
      )}
    />
  );
};

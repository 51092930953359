// @ts-nocheck
import { subDays, subMonths, subYears } from 'date-fns';
import html2pdf from 'html2pdf.js';
import { store } from '../../app';
import { setAccountSettingsTab, setDialog } from '../../features';
import {
  setConfirmDialog,
  setInformativeDialog,
  setUpdateContributionStatusRequest,
} from '../components';
import {
  AccountSettingsTabs,
  AccountType,
  booleanToTextValues,
  ConfirmDialogType,
  Dialogs,
  InformativeDialog,
  InstanceSettingsType,
  ContributionActions,
  ContributionStatus,
} from '../enums';
import { Account, GridModel, Instance, Contribution } from '../interfaces';
import { getContributionStatusLabel } from './incentives';

export function isNullOrWhitespace(value?: string | null): boolean {
  return !value || value.trim().length === 0 || value === null;
}

export function formatString(
  field?: string,
  prefix: string = ' ',
  suffix: string = '',
  upperCase = false,
): string {
  if (!isNullOrWhitespace(field)) {
    const value = `${prefix}${field!.trim()}${suffix}`;
    if (upperCase) {
      return value.toUpperCase();
    }
    return value;
  }
  return '';
}

export function getGenericHeight(usedHeight: number, usePercentage?: boolean): string {
  return `calc(100${usePercentage ? '%' : 'vh'} - ${usedHeight}px) !important`;
}

const isoDateFormat =
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/;

export function getDateString(date?: Date, withoutTime?: boolean): string | undefined {
  if (!date || !isoDateFormat.test(date?.toString())) return undefined;
  return withoutTime ? new Date(date!).toLocaleDateString() : new Date(date!).toLocaleString();
}

export function getAllRightsReservedText(): string {
  return `© ${new Date().getFullYear()} ScaleWith, Inc. All rights reserved.`;
}

export const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

export const formatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
});

export function emailValid(value: string): boolean {
  const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;
  return value ? emailRegex.test(value.trim()) : false;
}

export function getZipCodeString(value: string | undefined): string | undefined {
  return value && value.length > 5 ? `${value.slice(0, 5)}-${value.slice(5)}` : value;
}

export function getUsernameFromEmail(value?: string) {
  return value?.split('@')[0];
}

export function redirect(url: string) {
  window.location.replace(url);
}

export function openNewWindow(url: string) {
  window.open(url);
}

export function openConfirmDialog(
  value: {
    title: string;
    text?: string;
    type: ConfirmDialogType;
    confirmText?: string;
    value?: string;
  },
  width?: 'sm' | 'md' | 'xs' | 'lg' | 'xl',
) {
  store.dispatch(setConfirmDialog(value));
  store.dispatch(setDialog({ open: true, type: Dialogs.ConfirmDialog, maxWidth: width ?? 'sm' }));
}

export function updateMultipleGives(gridState: GridModel<Contribution>, status: ContributionActions) {
  if (!gridState?.selectionModel || gridState.selectionModel.length < 1) return;
  store.dispatch(
    setUpdateContributionStatusRequest(
      gridState.selectionModel.length === 1
        ? {
            id: gridState.selectionModel[0].toString(),
            proposedStatus: status,
            action: getContributionStatusLabel(status),
            showSuccessModal: false,
          }
        : {
            incentives: gridState.data.results.filter((x) =>
              gridState.selectionModel!.find((selected) => selected === x.id),
            ),
            proposedStatus: status,
            action: getContributionStatusLabel(status),
            showSuccessModal: false,
          },
    ),
  );
  store.dispatch(setDialog({ open: true, type: Dialogs.UpdateContributionStatus, maxWidth: 'md' }));
}

export function openInformativeDialog(
  value: {
    title: string;
    text?: string;
    contentTitle?: string;
    type: InformativeDialog;
    confirmText?: string;
    value?: string;
    hideCheckBox?: boolean;
  },
  width?: 'sm' | 'md' | 'xs' | 'lg' | 'xl',
) {
  store.dispatch(setInformativeDialog(value));
  store.dispatch(
    setDialog({ open: true, type: Dialogs.InformativeDialog, maxWidth: width ?? 'sm' }),
  );
}

export function openAccountSettingsOnTab(tab: AccountSettingsTabs) {
  store.dispatch(setAccountSettingsTab(tab));
  store.dispatch(setDialog({ open: true, type: Dialogs.AccountSettings, maxWidth: 'md' }));
}

export function booleanToText(value: boolean, type?: booleanToTextValues) {
  switch (type) {
    case booleanToTextValues.DeactivateActive:
      return value ? 'Activate' : 'Deactivate';
    case booleanToTextValues.ResolveUnresolve:
      return value ? 'Resolve' : 'Unresolve';
    default:
      return value ? 'Yes' : 'No';
  }
}

export function downloadFile(data: Blob, filename: string) {
  const url = URL.createObjectURL(data);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = url;
  tempLink.setAttribute('download', filename);
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  setTimeout(() => {
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(url);
  }, 200);
}

export function includesWithoutCaseSensi(mainValue: string, include: string) {
  const value = mainValue.toLowerCase();
  const valueInclude = include.toLowerCase();
  const isInclude: boolean = value.includes(valueInclude);
  return isInclude;
}

export function capitalizeFirstLetter(text: string) {
  if (!text) return '';
  if (text.length < 2 || isNullOrWhitespace(text.trim())) return text;
  const value = text.trim();
  const finalText = value.charAt(0).toUpperCase() + value.slice(1);
  return finalText;
}

export function subtractDateDays(days: number) {
  const to: Date = new Date();
  const from: Date = subDays(to, days);
  return { to: to.toISOString(), from: from.toISOString() };
}

export function subtractDateMonths(month: number) {
  const to: Date = new Date();
  const from: Date = subMonths(to, month);
  return { to: to.toISOString(), from: from.toISOString() };
}

export function subtractDateYears(years: number) {
  const to: Date = new Date();
  const from: Date = subYears(to, years);
  return { to: to.toISOString(), from: from.toISOString() };
}

export function getDateLastCalendarYear() {
  const date = new Date();
  const to: Date = new Date(`${date.getFullYear() - 1}-12-31`);
  const from: Date = new Date(`${date.getFullYear() - 1}-01-01`);
  return { to: to.toISOString(), from: from.toISOString() };
}

export function getDateFromJanuaryToNow() {
  const to: Date = new Date();
  const from: Date = new Date(`${to.getFullYear()}-01-01`);
  return { to: to.toISOString(), from: from.toISOString() };
}

export function getSubstrTitle(text: string, maxTitleLength: number) {
  if (text.length <= maxTitleLength!) return text;
  return `${text.substring(0, maxTitleLength)}...`;
}

export function isSubscriptionRequired(account?: Account, instance?: Instance) {
  if (instance?.type === InstanceSettingsType.default) {
    return instance?.settings?.stripe?.prices?.required !== undefined
      ? instance?.settings?.stripe?.prices?.required
      : false;
  }
  if (
    account?.type === AccountType.procurement &&
    instance?.type === InstanceSettingsType.procurement
  ) {
    return instance?.settings?.stripe?.prices?.required !== undefined
      ? instance?.settings?.stripe?.prices?.required
      : false;
  }
  if (
    account?.type === AccountType.supplier &&
    instance?.type === InstanceSettingsType.procurement
  ) {
    return instance?.settings?.stripe?.suppliers?.required !== undefined
      ? instance?.settings?.stripe?.suppliers?.required
      : false;
  }
  return true;
}

export function Visible({ condition, children }: { condition: boolean; children: JSX.Element }) {
  return condition ? children : null;
}

export function isAvailableShareByStatus(orderStatus: ContributionStatus): boolean {
  return orderStatus === ContributionStatus.Approved;
}

export function platform(): string {
  const platformUrl = window.location.href || 'scale';
  switch (true) {
    case platformUrl.includes('give'):
      return 'give';
    case platformUrl.includes('buy'):
      return 'buy';
    case platformUrl.includes('sell'):
      return 'sell';
    default:
      return 'scale';
  }
}

// TODO: this will include sellWithSelect and buyWithSelect as well in future
export function platformProductType(): string {
  const platformType = platform();
  let productTypeLocal = '';
  if (platformType === 'buy') {
    productTypeLocal = 'buyWith';
  } else if (platformType === 'sell') {
    productTypeLocal = 'sellWith';
  }
  return productTypeLocal;
}

export const stripHtmlTags = (str: string) => {
  if (typeof str !== 'string') {
    return str;
  }
  return str.replace(/<[^>]*>/g, '');
};

export const createPdfWithLinks = async (props: {
  elementId: string;
  pdfName: string;
  link?: string;
}) => {
  const { elementId, pdfName } = props;
  const input = document.getElementById(elementId) as HTMLElement;
  const elementWidth = input.offsetWidth;
  const elementHeight = input.offsetHeight;

  // Exclude elements with the "exclude-from-pdf" class
  const excludedElements = document.getElementsByClassName('exclude-from-pdf');
  Array.from(excludedElements).forEach((element) => {
    const newElement = element;
    newElement.style.display = 'none';
    return newElement;
  });

  const hiddenImage = document.getElementById('hiddenImage') as HTMLElement;
  if (hiddenImage) {
    hiddenImage.style.display = 'block';
    const vid = document.querySelector('iframe');
    if (vid) {
      vid.style.display = 'none';
    }
  }

  // Convert the image elements' src to blobs and set them as the src for the img elements
  const imageElements = input.getElementsByTagName('img');
  const imageBlobs = await Promise.all(
    Array.from(imageElements).map(async (image: HTMLImageElement) => {
      try {
        const response = await fetch(image.src, { cache: 'no-store', mode: 'cors' });
        return response.blob();
      } catch (error) {
        console.error(`Failed to convert image to blob: ${error}`);
        return new Blob(['', '']);
      }
    }),
  );

  Array.from(imageElements).forEach((image: HTMLImageElement, index: number) => {
    if (imageBlobs[index]) {
      if (imageBlobs[index] instanceof Blob) {
        const blobUrl = URL.createObjectURL(imageBlobs[index]);
        image.src = blobUrl;
      } else {
        image.src = 'givewith-front-end-library/src/assets/img/default_program_image.png';
      }
    }
  });

  html2pdf()
    .set({
      filename: pdfName,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 1 },
      jsPDF: {
        unit: 'px',
        format: [elementWidth, elementHeight],
        orientation: 'portrait',
      },
      margin: [0, 0, 0, 0],
      enableLinks: true, // Enable clickable links in the PDF
      pagebreak: '',
    })
    .from(input)
    .save()
    .finally(() => {
      // Show the excluded elements again
      Array.from(excludedElements).forEach((element) => {
        element.style.display = 'block';
      });
    });
  return Promise;
};

export function replaceStringAtIndex(arr: string[], index: number, newString: string): string[] {
  if (index < 0 || index >= arr.length) {
    throw new Error(`Index ${index} is out of range for array of length ${arr.length}`);
  }

  const newArr = [...arr]; // create a new copy of the array

  newArr[index] = newString; // replace the string at the specified index

  return newArr;
}


export function isOnRoute(desiredRoute) {
  const currentPath = window.location.pathname;
  return currentPath.startsWith(desiredRoute);
}
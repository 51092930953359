import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { SupplierUserList } from '../supplierInviteEntryDialog/supplierInviteSlice';

export interface SupplierCSVState {
  selected: Array<string>;
  supplierUserList: SupplierUserList;
}

export interface SupplierCSVStateChecked {
  isChecked: boolean;
}

const initialState1: SupplierCSVState = {
  selected: [],
  supplierUserList: [],
};
const initialState2: SupplierCSVStateChecked = {
  isChecked: false
}

const supplierInviteCSVSlice = createSlice({
  name: 'supplier CSV',
  initialState:{
    initialState1,
    initialState2
  },
  reducers: {
    clearSupplierCSVState: (state) => {
      _.assign(state, initialState1);
    },
    setSupplierInviteCSVData: (state, action: PayloadAction<Partial<SupplierCSVState>>) => {
      _.assign(state.initialState1, action.payload);
    },
    clearSupplierInviteCSVDataByKey: (state, action: PayloadAction<keyof SupplierCSVState>) => {
      state.initialState1[action.payload] = [] ;
    },
    setSupplierInviteCSVSelect: (state, action: PayloadAction<Array<string>>) => {
      state.initialState1.selected = action.payload;
    },
    toggleCheckBox: (state) => {
      state.initialState2.isChecked = !state.initialState2.isChecked
    }
  },
});

export const {
  clearSupplierCSVState,
  setSupplierInviteCSVData,
  setSupplierInviteCSVSelect,
  clearSupplierInviteCSVDataByKey,
  toggleCheckBox
} = supplierInviteCSVSlice.actions;

export const supplierInviteCSVReducer = supplierInviteCSVSlice.reducer;

import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import {} from '@mui/icons-material';
import { FlexBox, FormDialog } from '../../../shared/components';
import { Dialogs, SupplierInviteScreenStatus } from '../../../shared/enums';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import {
  clearInviteSuppliersState,
  getSupplierInviteIndustriesGroupedThunk,
  postSupplierInviteThunk,
  setSupplierInviteList,
  setSupplierInviteScreenStatus,
} from './supplierInviteEntryDialog';
import { SupplierEntryForm } from './supplierInviteEntryDialog/SupplierEntryForm';
import { SupplierInviteCSV } from './SupplierInviteCSV/SupplierInviteCSV';
import { SupplierLoading } from './SupplierInviteCSV';
import { emailValid, isNullOrWhitespace } from '../../../shared/utils';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';

export const InviteSupplier = () => {
  const state = useAppSelector((x) => x.inviteSupplier);
  const stateCSV = useAppSelector((x) => x.inviteSupplierCSV);
  const { listSuppliers, loading, success, screenStatus, industriesGrouped } = { ...state };
  const { supplierUserList, selected } = { ...stateCSV.initialState1 };
  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      dispatch(clearInviteSuppliersState());
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(getSupplierInviteIndustriesGroupedThunk());
  }, [dispatch]);

  const checkRequiredValue = (val: string, msg: string, messages: Array<string>) => {
    if (isNullOrWhitespace(val)){
      messages.push(msg);
    }
  }
  const addSupplierToList = () => {
    // const itemSelected = selected.filter((item) => item !== undefined);
    // const keyToMap = itemSelected[0];
    // if (!itemSelected || !keyToMap) return;
    // const getValidArray = stateCSV.initialState1[keyToMap as keyof typeof stateCSV.initialState1];

    const csvSupplierUserList = supplierUserList.map((value) => {
      const errorMessagesClient: Array<string> = [];
      checkRequiredValue(value.username, "Email is required", errorMessagesClient);
      if (!emailValid(value.username)){
        errorMessagesClient.push("Email is not valid");
      }
      checkRequiredValue(value.firstName, "First Name is required", errorMessagesClient);
      checkRequiredValue(value.lastName, "Last Name is required", errorMessagesClient);
      checkRequiredValue(value.company, "Company is required", errorMessagesClient);
      checkRequiredValue(value.industry, "Industry is required", errorMessagesClient);
      if (!industriesGrouped.find(
          (item) => item.label.toLowerCase() === value.industry.toLowerCase())){
        errorMessagesClient.push("Industry not recognized");
      }
      const industryInfo = industriesGrouped.find(
        (item) => item.label?.toLowerCase() === value.industry.toLowerCase());
      return {
        value: {
          account: {
            industry: {
              industry: industryInfo?.group ?? '',
              subIndustry: industryInfo?.value ?? '',
            },
            company: {
              name: value.company ?? '',
            },
          },
          user: {
            username: value.username ?? '',
            firstName: value.firstName ?? '',
            lastName: value.lastName ?? '',
          },
        },
        errorMessagesClient,
      };
    });

    const payload = [...listSuppliers, ...csvSupplierUserList];

    dispatch(setSupplierInviteList(payload));
    dispatch(setSupplierInviteScreenStatus(SupplierInviteScreenStatus.Form));
  };

  const isSupplierInviteListInvalid = () =>
    supplierUserList.length === 0 || !selected.find((item) => item === 'company') ||
      !selected.find((item) => item === 'firstName') ||
      !selected.find((item) => item === 'industry') ||
      !selected.find((item) => item === 'lastName') ||
      !selected.find((item) => item === 'username');

  const isInviteInvalid = () => listSuppliers.length <= 0;
  const isInviteInvalidByCSV = () =>
    listSuppliers.some(
      (item) => item.errorMessagesClient !== undefined && item.errorMessagesClient.length > 0,
    );

  const onHandleInvite = () => {
    dispatch(postSupplierInviteThunk());
  };

  const COMPONENTS = {
    Form: <SupplierEntryForm />,
    Loading: <SupplierLoading />,
    UploadedCSV: <SupplierInviteCSV />,
  };

  const BUTTONS = {
    Form: (
      <Button
        variant="contained"
        disabled={isInviteInvalid() || isInviteInvalidByCSV()}
        onClick={onHandleInvite}
        size="large"
        sx={{ backgroundColor: colors.primary, color: colors.secondary, fontWeight: fonts.fontWeight700 }}
      >
        Invite
      </Button>
    ),
    Loading: undefined,
    UploadedCSV: (
      <FlexBox sx={{ gap: 1 }}>
        <Button
          color="info"
          size="large"
          onClick={() => dispatch(setSupplierInviteScreenStatus(SupplierInviteScreenStatus.Form))}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isSupplierInviteListInvalid()}
          onClick={addSupplierToList}
          size="large"
        >
          Continue
        </Button>
      </FlexBox>
    ),
  };

  return (
    <FormDialog
      title="Invite Supplier"
      type={Dialogs.InviteSupplier}
      hideCancel={screenStatus === SupplierInviteScreenStatus.UploadedCSV}
      loading={loading}
      closeFlag={success}
      Buttons={BUTTONS[screenStatus]}
    >
      {COMPONENTS[screenStatus]}
    </FormDialog>
  );
};

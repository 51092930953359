/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Popper,
  // Typography,
  ClickAwayListener,
} from '@mui/material';
import { Info } from '@mui/icons-material';
import React, { FC, useMemo, useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { useLocation } from 'react-router-dom';
import { AccountContributionFields, SelectAmountFormData } from '../../../shared/interfaces';
import { isNullOrWhitespace } from '../../../shared/utils';
import { MoneyInputWithLocaleSymbol, StyledInput } from '../../../shared/components';
import { CompanySearch } from '../../../shared/components/CompanySearch';
import { getInstanceThunk } from '../../instances/instance-settings/instanceSettingsSlice';

import PercentDropdown from './PercentDropDown';
import { useAppDispatch, useAppSelector, useInstance } from '../../../shared/hooks';
import BuyerEmailDropDown from './BuyerEmailDropDown';
import EmailInput from './EmailInput';
import fonts from '../../../assets/scss/font.module.scss';
import colors from '../../../assets/scss/color.module.scss';

interface Props {
  // details?: boolean;
  data?: SelectAmountFormData;
  disabled?: boolean;
  customFields?: AccountContributionFields;
  onFormChange?: (value: SelectAmountFormData) => void;
  companySplCharValidation?: boolean;
  onChangeAccordianClose?: (value: any) => void;
}

export const AmountForm: FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const {
    data,
    disabled,
    onFormChange,
    customFields,
    companySplCharValidation = true,
    onChangeAccordianClose,
  } = props;
  const { account } = useAppSelector((state) => state.app);
  const instanceSettings = useAppSelector((x) => x.instanceSettings);
  const { settings } = instanceSettings.values;
  const minFundingAmount = settings?.minimumFundingAmount || 1;
  const defaultContributionPercentage = settings?.defaultIncentivePercentage || 0.01;
  const location = useLocation();
  const { currencySymbol } = useInstance();
  const {
    quoteAmount: quoteAmountAccount,
    // quoteNumber: quoteNumberAccount,
    description: descriptionAccount,
    customField1,
    customField2,
    customField3,
    customField4,
  } = { ...customFields };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElEmails, setAnchorElEmails] = useState<null | HTMLElement>(null);
  const [dealAmount, setDealAmount] = useState(data?.quoteAmount ? data?.quoteAmount > 0 : false);
  const [openIncentive, setOpenIncentive] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [previousTDV, setPreviousTDV] = useState(data?.quoteAmount);
  useEffect(() => {
    if (isNullOrWhitespace(account?.instance?.id)) return;
    dispatch(getInstanceThunk(account?.instance?.id || ''));
  }, [dispatch, account?.instance?.id]);
  const onChangeClose = (value: any) => {
    if (onChangeAccordianClose) {
      onChangeAccordianClose(value);
    }
  };

  const customerName = useMemo(
    () => (
      <Grid item xs={4} md={4} className="content_container">
        <InputLabel shrink htmlFor="bootstrap-input" className="inputLabel">
          Buyer
          <Tooltip
            title="Input the company for which you are selling to."
            placement="top"
            arrow
            TransitionComponent={Zoom}
          >
            <Info sx={{ color: `${colors.textSecondary} !important` }} />
          </Tooltip>
        </InputLabel>
        <FormControl fullWidth>
          {account?.type === 'supplier' ? (
            <StyledInput
              sx={{
                fontSize: fonts.fontSize16,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
              fullWidth
              value={account?.invitedBy?.name}
              readOnly
            />
          ) : (
            <CompanySearch
              onChange={(e, newValue) => {
                if (!onFormChange) return;
                onFormChange({ customerName: newValue.name, customerId: newValue.id });
              }}
              customerName={!location.pathname.includes('incentive/new') ? data?.customerName : ''}
              validateSpecialChar={companySplCharValidation}
              app="sellwith"
            />
          )}
        </FormControl>
      </Grid>
    ),
    [data?.customerName, disabled],
  );
  const customerEmails = useMemo(
    () => (
      <Grid item xs={4} md={4} className="content_container">
        <InputLabel shrink htmlFor="bootstrap-input" className="inputLabel">
          Buyer Email ID (optional){' '}
          <Tooltip title="Buyer Email Id" placement="top" arrow TransitionComponent={Zoom}>
            <Info sx={{ color: `${colors.textSecondary} !important` }} />
          </Tooltip>
        </InputLabel>
        {/* <FormControl variant="standard" fullWidth> */}

        {/* <LightTooltip title="Enter the emails of the buyers for which you are selling to."> */}
        {/* <StyledInput
              sx={{
                "& input::placeholder": {
                  fontStyle: "italic"
                
              }}}
              placeholder='Enter buyer email-id'
              fullWidth
              value={data?.emails?.join(', ')}
              readOnly
              onFocus={(e) => setAnchorElEmails(e.target)}
              onClick={() => setOpenEmail(true)}
            /> */}
        {/* </LightTooltip> */}
        <EmailInput emailAddresses={data?.emails ?? []} />
        {/* </FormControl> */}
      </Grid>
    ),
    [data?.emails],
  );
  // const customerId = useMemo(
  //   () => (
  //     <Box className="content_container">
  //       {!data?.quoteNumber && <Typography>Buyer Number</Typography>}
  //       <LightTooltip title="What is this customer's unique customer number or identifier?">
  //         <TextField
  //           label={customerIdAccount?.label ?? 'Sell to Customer #'}
  //           fullWidth
  //           variant="outlined"
  //           required={customerIdAccount?.required ?? false}
  //           value={data?.customerId ?? ''}
  //           onChange={(e) => {
  //             if (!onFormChange) return;

  //             onFormChange({ customerId: e.target.value });
  //           }}
  //           disabled={disabled}
  //         />
  //       </LightTooltip>
  //     </Box>
  //   ),
  //   [data?.customerId, disabled],
  // );

  const description = useMemo(
    () => (
      <Grid item xs={4} md={4} className="content_container">
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink htmlFor="bootstrap-input" className="inputLabel">
            Opportunity Name{' '}
            <Tooltip
              title="How would you describe this opportunity?"
              placement="top"
              arrow
              TransitionComponent={Zoom}
            >
              <Info sx={{ color: `${colors.textSecondary} !important` }} />
            </Tooltip>
          </InputLabel>

          <StyledInput
            className="enter-opportunity-name-input"
            sx={{
              fontSize: fonts.fontSize16,
              fontWeight: fonts.fontWeight600,
              color: colors.textPrimary,
            }}
            placeholder="Enter opportunity name"
            fullWidth
            inputProps={{ maxLength: 32 }}
            required={descriptionAccount?.required ?? false}
            value={data?.description ?? ''}
            onChange={(e) => {
              if (!onFormChange) return;

              onFormChange({ description: e.target.value });
            }}
            disabled={disabled}
          />
        </FormControl>
      </Grid>
    ),
    [data?.description, disabled],
  );

  // const quoteNumber = useMemo(
  //   () => (
  //    <Grid item xs={4} md={4} className="content_container">
  //       <FormControl fullWidth>
  //         <InputLabel shrink htmlFor="bootstrap-input">
  //           {quoteNumberAccount?.label ?? 'Quote #'}
  //         </InputLabel>
  //         <LightTooltip title="Quote #, proposal #, or opportunity ID of deal to which this contribution is tied?">
  //           <StyledInput
  //             // label={quoteNumberAccount?.label ?? 'Quote #'}
  //             fullWidth
  //             required={quoteNumberAccount?.required ?? false}
  //             value={data?.quoteNumber ?? ''}
  //             onChange={(e) => {
  //               if (!onFormChange) return;
  //               onFormChange({ quoteNumber: e.target.value });
  //             }}
  //             disabled={disabled}
  //           />
  //         </LightTooltip>
  //       </FormControl>
  //     </Grid>
  //   ),
  //   [data?.quoteNumber, disabled],
  // );
  const formatNumberWithCommas = (inputNumber: string) =>
    inputNumber?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const formatValueWithCommas = (inputNumber: string) => {
    const formattedNumber = inputNumber.replace(/,/g, '');
    const decimalIndex = formattedNumber.indexOf('.');
    if (decimalIndex !== -1) {
      const integerPart = formattedNumber
        .slice(0, decimalIndex)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      const decimalPart = formattedNumber.slice(decimalIndex + 1, decimalIndex + 3);
      return `${integerPart}.${decimalPart}`;
    }
    return formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const formatedValue = () => {
    const isZeros = data?.grandTotal?.toString().split('.')[1]?.slice(0, 2);
    if (data?.grandTotal && isZeros === '00') {
      return Math.floor(data?.grandTotal).toString();
    }
    return data?.grandTotal?.toString() || '';
  };

  useEffect(() => {
    if (previousTDV !== data?.quoteAmount) {
      onFormChange?.({
        grandTotal: undefined,
      });
    }
  }, [previousTDV, data?.quoteAmount]);

  const incentiveAmount = useMemo(
    () => (
      <Grid item xs={4} md={4} className="content_container">
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="bootstrap-input" className="inputLabel">
            Total Contribution Amount{' '}
            <Tooltip
              title="What is the total contribution amount?"
              placement="top"
              arrow
              TransitionComponent={Zoom}
            >
              <Info sx={{ color: `${colors.textSecondary} !important` }} />
            </Tooltip>
          </InputLabel>
          <StyledInput
            sx={{
              fontSize: fonts.fontSize16,
              fontWeight: fonts.fontWeight600,
              color: colors.textPrimary,
            }}
            className="enter-total-contribution-input"
            placeholder="Enter total contribution "
            readOnly
            required={quoteAmountAccount?.required ?? true}
            fullWidth
            value={data?.grandTotal ? formatValueWithCommas(formatedValue()) : ''}
            onFocus={(e) => setAnchorEl(e.target)}
            onClick={() => {
              if (dealAmount) setOpenIncentive(true);
            }}
            startAdornment={
              <InputAdornment
                sx={{ height: '4rem' }}
                className="inputAdornment"
                position="start"
                variant="outlined"
              >
                {currencySymbol}
              </InputAdornment>
            }
            // onBlur={() => setAnchorEl(null)}
            onChange={(e) => {
              if (!onFormChange) return;
              const { value } = e.target;
              // const formattedValue = value.replace
              onFormChange({
                grandTotal: isNullOrWhitespace(value)
                  ? undefined
                  : parseFloat(value.replace(/,/g, '')),
              });
            }}
            inputProps={{ inputComponent: MoneyInputWithLocaleSymbol as any }}
            disabled={disabled || !dealAmount}
          />
        </FormControl>
      </Grid>
    ),
    [data?.grandTotal, disabled, dealAmount, previousTDV],
  );
  const sellerName = () => (
    <Box className="content_container">
      <FormControl fullWidth>
        <InputLabel shrink htmlFor="bootstrap-input" className="inputLabel">
          Seller
          <Tooltip title="Seller Name" placement="top" arrow TransitionComponent={Zoom}>
            <Info sx={{ color: `${colors.textSecondary} !important` }} />
          </Tooltip>
        </InputLabel>
        <StyledInput
          sx={{
            backgroundColor: '#F2F2F3',
            fontSize: fonts.fontSize16,
            fontWeight: fonts.fontWeight600,
            color: colors.textPrimary,
          }}
          fullWidth
          value={account?.company?.name}
          readOnly
        />
      </FormControl>
    </Box>
  );

  const quoteAmount = useMemo(
    () => (
      <Grid item xs={4} md={4} className="content_container">
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="bootstrap-input" className="inputLabel">
            {quoteAmountAccount?.label ?? 'Total Deal Value'}{' '}
            <Tooltip
              title="What is the total cost to the customer of the deal associated with this contribution?"
              placement="top"
              arrow
              TransitionComponent={Zoom}
            >
              <Info sx={{ color: `${colors.textSecondary} !important` }} />
            </Tooltip>
          </InputLabel>
          <StyledInput
            sx={{
              fontSize: fonts.fontSize16,
              fontWeight: fonts.fontWeight600,
              color: colors.textPrimary,
            }}
            className="enter-total-deal-value-input"
            placeholder="Enter total deal value "
            // label={quoteAmountAccount?.label ?? 'Total Deal Value'}
            required={quoteAmountAccount?.required ?? false}
            fullWidth
            startAdornment={
              <InputAdornment sx={{ height: '4rem' }} className="inputAdornment" position="start">
                {currencySymbol}
              </InputAdornment>
            }
            value={data?.quoteAmount ? formatNumberWithCommas(data?.quoteAmount.toString()) : ''}
            onChange={(e) => {
              setPreviousTDV(data?.quoteAmount);
              if (!onFormChange) return;
              const { value } = e.target;
              if (e.target.value.length > 0) {
                setDealAmount(true);
              } else {
                setDealAmount(false);
              }
              onFormChange({
                quoteAmount: isNullOrWhitespace(value)
                  ? undefined
                  : parseFloat(value.replace(/,/g, '')),
              });
            }}
            inputProps={{ inputComponent: MoneyInputWithLocaleSymbol as any }}
            disabled={disabled}
          />
        </FormControl>
      </Grid>
    ),
    [data?.quoteAmount, disabled],
  );

  const customForm1 = useMemo(
    () => (
      <Grid item xs={4} md={4} className="content_container">
        <FormControl>
          <InputLabel shrink htmlFor="bootstrap-input" className="inputLabel">
            {customField1?.label} <Info />
          </InputLabel>
          <StyledInput
            fullWidth
            required={customField1?.required}
            value={data?.customForm1 ?? ''}
            disabled={disabled}
            onChange={(e) => {
              if (!onFormChange) return;
              onFormChange({ customForm1: e.target.value });
            }}
          />
        </FormControl>
      </Grid>
    ),
    [data?.customForm1, disabled],
  );

  const customForm2 = useMemo(
    () => (
      <Grid item xs={4} md={4} className="content_container">
        <StyledInput
          // label={customField2?.label}
          required={customField2?.required}
          fullWidth
          value={data?.customForm2 ?? ''}
          disabled={disabled}
          onChange={(e) => {
            if (!onFormChange) return;
            onFormChange({ customForm2: e.target.value });
          }}
        />
      </Grid>
    ),
    [data?.customForm2, disabled],
  );

  const customForm3 = useMemo(
    () => (
      <Grid item xs={4} md={4} className="content_container">
        <StyledInput
          // label={customField3?.label}
          required={customField3?.required}
          fullWidth
          value={data?.customForm3 ?? ''}
          disabled={disabled}
          onChange={(e) => {
            if (!onFormChange) return;
            onFormChange({ customForm3: e.target.value });
          }}
        />
      </Grid>
    ),
    [data?.customForm3, disabled],
  );

  const customForm4 = useMemo(
    () => (
      <Grid item xs={4} md={4} className="content_container">
        <StyledInput
          // label={customField4?.label}
          required={customField4?.required}
          // variant="outlined"
          fullWidth
          value={data?.customForm4 ?? ''}
          disabled={disabled}
          onChange={(e) => {
            if (!onFormChange) return;
            onFormChange({ customForm4: e.target.value });
          }}
        />
      </Grid>
    ),
    [data?.customForm4, disabled],
  );
  return (
    <Grid container spacing={2}>
      {sellerName()}
      {customerName}
      {customerEmails}
      {description}
      {quoteAmount}
      {incentiveAmount}
      {openIncentive && (
        <ClickAwayListener onClickAway={() => setOpenIncentive(false)}>
          <Popper
            open={openIncentive}
            anchorEl={anchorEl}
            id={anchorEl ? 'simple-popper' : undefined}
            placement="bottom-start"
          >
            {' '}
            <PercentDropdown
              onClose={() => setOpenIncentive(false)}
              onChangeAccordianEffect={onChangeClose}
              onChange={(value) => {
                if (!onFormChange) return;

                onFormChange({
                  grandTotal: isNullOrWhitespace(value.toString()) ? undefined : value,
                });
                setAnchorEl(null);
              }}
              quoteAmount={data?.quoteAmount}
              currency={currencySymbol || '$'}
              isDealAmount={dealAmount}
              minFundingAmount={minFundingAmount}
              incentiveAmount={data?.grandTotal}
              defaultContributionPercentage={defaultContributionPercentage}
            />
          </Popper>
        </ClickAwayListener>
      )}
      {openEmail && (
        <ClickAwayListener onClickAway={() => setOpenEmail(false)}>
          <Popper
            open={Boolean(anchorElEmails)}
            anchorEl={anchorElEmails}
            id={anchorElEmails ? 'simple-popper' : undefined}
            placement="bottom-start"
          >
            <BuyerEmailDropDown
              onClose={() => setOpenEmail(false)}
              emailAddresses={data?.emails ?? []}
              onChange={(value) => {
                if (!onFormChange) return;
                onFormChange({ emails: value });
                setAnchorElEmails(null);
              }}
            />
          </Popper>
        </ClickAwayListener>
      )}
      {/* {quoteNumber} */}
      {!isNullOrWhitespace(customField1?.label) && customForm1}
      {!isNullOrWhitespace(customField2?.label) && customForm2}
      {!isNullOrWhitespace(customField3?.label) && customForm3}
      {!isNullOrWhitespace(customField4?.label) && customForm4}
    </Grid>
  );
};

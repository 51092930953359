import React, { useEffect } from 'react';
import { 
  Box,
  // Checkbox,
  // FormControl,
  // MenuItem,
  Paper,
  // Select,
  // SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography } from '@mui/material';
//  *BoldTypography, FlexBox,
import {  PaddingRoundedBox } from '../../../../shared/components';
// import { SupplierInviteDataCard } from './SupplierInviteDataCard';
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks';
import {
  clearSupplierCSVState,
  // clearSupplierInviteCSVDataByKey,
  setSupplierInviteCSVData,
  setSupplierInviteCSVSelect,
  // toggleCheckBox
} from './supplierInviteCSVSlice';
// import { isNullOrWhitespace } from '../../../../shared/utils';

export const SupplierInviteCSV = () => {
  // const stateInviteSupplierCSV = useAppSelector((x) => x.inviteSupplierCSV);
  const state = useAppSelector((x) => x.inviteSupplier);
  const csvSupplierUserList = useAppSelector((x) => x.inviteSupplier.csvSupplierUserList);
  // const {isChecked} = { ...stateInviteSupplierCSV.initialState2 }
  const { headers } = {
    ...state,
  };
  // TODO: remove if expecting CSV to always have mandated headers
  // const csvHeaderOptions = Object.keys( csvSupplierUserList.find(item => item != null) );
  const dispatch = useAppDispatch();
  // const { selected } = { ...stateInviteSupplierCSV.initialState1 };

  useEffect(
    () => () => {
      dispatch(clearSupplierCSVState());
    },
    [dispatch],
  );

  // ???  :-|
  // const csvHeaderCheck = () => csvHeaderOptions.toLocaleString().toLowerCase().split(',').includes(headers[0].toLowerCase())

  useEffect(() => {
    // if(csvHeaderCheck()){
    dispatch(setSupplierInviteCSVSelect(headers));
    dispatch(setSupplierInviteCSVData({ supplierUserList: csvSupplierUserList }))
      // headers.map((header,index)=>(
      //   
      // ))
    // }
  },[])
  
  // const onChange = (value: string, index: number) => {
  //   const copySelected = [...selected];
  //   const isAlreadySelected = copySelected.findIndex((item) => item === value);
  //   if (!isNullOrWhitespace(copySelected[index])) {
  //     dispatch(
  //       clearSupplierInviteCSVDataByKey(copySelected[index] as keyof typeof stateInviteSupplierCSV.initialState1),
  //     );
  //   }
  //   if (isAlreadySelected !== -1) {
  //     copySelected[isAlreadySelected] = '';
  //   }
  //   copySelected[index] = value;
  //   dispatch(setSupplierInviteCSVSelect([...copySelected]));
  //   dispatch(setSupplierInviteCSVData({ [value]: csvSupplierUserList[index] }));
  // };

  return (
    <Box sx={{ height: 565 }}>
      <Typography sx={{ marginBottom: 1 }}>
        Preview Supplier User Invitations
      </Typography>
      {/* <Checkbox
      checked={isChecked}
      onChange={()=> dispatch(toggleCheckBox())}
      inputProps={{ 'aria-label': 'controlled' }}
    />Use First Row as headers */}
      <PaddingRoundedBox
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          backgroundColor: theme.palette.common.grey.main,
        })}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="suppliers from csv">
            <TableHead>
              <TableRow>
                <TableCell>First Name</TableCell>
                <TableCell align="right">Last Name</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">Company</TableCell>
                <TableCell align="right">Industry</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {csvSupplierUserList.map((row) => (
                <TableRow
                  key={row.username}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.firstName}
                  </TableCell>
                  <TableCell align="right">{row.lastName}</TableCell>
                  <TableCell align="right">{row.username}</TableCell>
                  <TableCell align="right">{row.company}</TableCell>
                  <TableCell align="right">{row.industry}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <FlexBox sx={{ alignItems: 'start', gap: 2 }}>
          <BoldTypography sx={{ flex: 1, textAlign: 'center' }}> Imported Data</BoldTypography>
          <BoldTypography sx={{ flex: 1, textAlign: 'center' }}> Mapped</BoldTypography>
        </FlexBox> */}
        {/* {csvData.map((item, index) => (
          <FlexBox
            sx={{ alignItems: 'start', gap: 2 }}
            // eslint-disable-next-line react/no-array-index-key
            key={`mapped-Date-${item[0] ?? 'mappedData'}-${index}`}
          >
            <Box sx={{ flex: 1 }}>
              <SupplierInviteDataCard data={item} />
            </Box>
            <FlexBox sx={{ flex: 1, alignItems: 'center', gap: 3 }}>
              <Typography>Mapped to: </Typography>
              <FormControl sx={{ flex: 1 }}>
                <Select
                  labelId="filter-select-label"
                  id="filter-select"
                  variant="standard"
                  value={!csvHeaderCheck() ?  selected[index] : headers[index]}
                  onChange={(e: SelectChangeEvent) => onChange(e.target.value, index)}
                >
                    <MenuItem value="firstName">First Name</MenuItem>
                    <MenuItem value="lastName">Last Name</MenuItem>
                    <MenuItem value="company">Company</MenuItem>
                    <MenuItem value="industry">Industry</MenuItem>
                    <MenuItem value="username">Email</MenuItem>
                </Select>
              </FormControl>
            </FlexBox>
          </FlexBox>
        ))} */}

      </PaddingRoundedBox>
    </Box>
  );
};

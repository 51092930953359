import { GridResponse, Transaction, TransactionGridRequest } from '../../shared/interfaces';
import { httpSecuredCommon, cancelToken, httpSecuredCoreCommon } from '../httpCommon';

const base = '/impact-transactions';

export const TRANSACTIONS_API_ROUTES = {
  get: base,
  post: base,
  getMonthReconciliation: `${base}/monthly-recon`,
  getInvoiceData: `${base}/documents/invoice`,
  generatepdfBinary: `api/v2/core/order/`

};

export const getTransactions = async (request: TransactionGridRequest) => {
  const response = await httpSecuredCommon.get<GridResponse<Transaction>>(
    TRANSACTIONS_API_ROUTES.get,
    {
      cancelToken: cancelToken.token,
      params: request,
    },
  );
  return response;
};

export const getMonthReconciliation = async () => {
  const response = await httpSecuredCommon.get(TRANSACTIONS_API_ROUTES.getMonthReconciliation, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const postTransaction = async (transaction: {
  account: { id: string; name: string };
  sageInvoiceId: string;
  memo: string;
  amount: number;
}) => {
  const response = await httpSecuredCommon.post(TRANSACTIONS_API_ROUTES.post, transaction, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const getInvoiceData = async (transaction: any) => {
  const request = 
  {
      "transactionType": transaction?.transactionType,
      "transactionId": transaction?.id
    
  }
  const response = await httpSecuredCommon.get<Transaction>(TRANSACTIONS_API_ROUTES.getInvoiceData, {
    cancelToken: cancelToken.token,
    params : request,
  });
  return response;
};

export const generatePDFBinary = async (orderId: String) => {
  const response = await httpSecuredCoreCommon.get(`${TRANSACTIONS_API_ROUTES.generatepdfBinary}${orderId}/invoice`, {
    cancelToken: cancelToken.token,
  });
  return response;
};